import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import he from 'he';

import { Form } from '@unform/web';
import axiosPrivate from 'config/axiosPrivate';

import {
  adminAnalyzerSelector,
  updateForm,
  updateTextForm,
} from 'store/Admin/Analyser';
import { updateTextOfTextList } from 'store/Admin/OrderDisplay';
import { UserSessionSelector } from 'store/UserSession';
import { fetchText } from 'store/Admin/Analyser/features';

import AnalyseText from 'components/Admin/AnalyseText';
import KeyWordListFormatter from 'components/Admin/KeyWordListFormatter';
import { CustomEditor } from 'components/shared/CustomEditor';
import { CustomHighcharts } from 'components/shared/CustomHighcharts';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { DownloadOneTextWordType } from 'components/shared/DownloadFile/DownloadTextWordType';
import { InputField } from 'components/shared/InputField';
import { Loader } from 'components/shared/Loader';
import { DisplayTextAmountColor } from 'components/shared/TextAmountColor';
import ComparisonModal from 'components/shared/modal/ComparisonModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import SavingIndicator from 'components/shared/AutoSaveDisplay';
import InternalLinksBox from 'components/shared/TextDisplay/InternalLinksBox';

import { ClipboardCopy } from 'components/shared/ClipboardCopy';
import { RevisionDetails } from 'components/shared/RevisionDetails';

import { capitalize } from 'helpers/Capitalize';
import { setKeyWordList, isValidFormat } from 'helpers/PdfFunction';
import { checkDataAdmin } from 'helpers/formHelper';
import { autoSaveText } from 'helpers/CreateAutoSave';
import { fetchAnalysisResult } from 'helpers/fetchAnalysisResultHelper';
import { useAutosave } from 'utils/hooks/useAutoSave';

import {
  ANALYSELANGAUGESLIST,
  SavingState,
  sentenceSeparators,
} from 'utils/Constants';

import axiosFacturation from 'config/axiosFacturation';

import './textDisplay.css';
import axiosStuffedDetector from 'config/axiosDetectStuffedKeywords';
import axiosMonetoring from 'config/axiosMonetoring';

import { replaceAmpersands } from 'utils/StringUtils';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import { animationUrl } from 'config/endpoints';
import { keywordsAutoGeneration } from 'utils/KeywordsAutoGeneration/keywordsAutoGeneration';
import {
  formatKeywordData,
  extractKeywordsWithFrequency,
  transformData,
  transformToSemanticGap,
  formatwebsiteUrls,
  extractAnchorTexts,
  extractStrKeywordsWithFrequency,
} from 'helpers/FormatKeywordsHelper';

const API = {
  updateAndDeliver: {
    baseUrl: '/texts/',
    suffix: '/admin-deliver',
  },
  putToDraft: {
    baseUrl: '/texts/',
    suffix: '/putToDraft',
  },
  reactivateInitialText: {
    baseUrl: '/texts/',
    suffix: '/reactivate',
  },
  verifyTextBeforeDeliver: {
    baseUrl: '/texts/',
    suffix: '/verify',
  },
};

export default function TextDisplay() {
  const {
    orderId,
    textForm,
    orderType,
    assistantId,
    orderContentType,
    isLoading,
    isError,
    textType,
    orderSiloName,
  } = useSelector(adminAnalyzerSelector);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { scheduleSave, isSaving } = useAutosave(autoSaveText, 1000);

  const { user } = useSelector(UserSessionSelector);
  const [disabledForm, setDisabledForm] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [analyseText, setAnalyseText] = useState('');

  const [textTitle, setTextTitle] = useState('');
  const [keyword, setkeyword] = useState('');

  const [url, setUrl] = useState('');
  const [titleTag, setTitleTag] = useState('');
  const [metaDescriptionTag, setMetaDescriptionTag] = useState('');

  const [actualTextContent, setActualTextContent] = useState('');
  const [actualHtmlContent, setActualHtmlContent] = useState('');
  const [initialTextContent, setInitialTextContent] = useState('');
  const [initialHtmlContent, setInitialHtmlContent] = useState('');
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const [initialTextWordCount, setInitialTextWordCount] = useState(0);

  const [analyseResult, setAnalyseResult] = useState('');
  const [list, setList] = useState({});

  const [keywords, setKeywords] = useState('');
  const [combinedKeywords, setCombinedKeywords] = useState('');
  const [combinedKeywordsList, setCombinedKeywordsList] = useState([]);
  const [websites, setWebsites] = useState('');
  const [language, setLanguage] = useState(ANALYSELANGAUGESLIST[0]);
  const [loading, setLoading] = useState(false);
  const [keywordStuffingLoading, setKeywordStuffingLoading] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  const [showComparison, setShowComparison] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const [stuffedKeywords, setStuffedKeywords] = useState([]);
  const [initialStuffedKeywords, setInitialStuffedKeywords] = useState([]);
  const [saving, setSaving] = useState(SavingState.NOT_SAVED);
  const [timer, setTimer] = useState(null);
  const [jobStarted, setJobStarted] = useState(null);
  const [startChecking, setStartChecking] = useState(false);
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [autoKeywordsGenerationLoading, setAutokeywordGenerationLoading] =
    useState(false);
  const [isTextVerified, setIsTextVerified] = useState(false);
  const [isAutomaticTextAnalysis, setIsAutomaticTextAnalysis] = useState(false);

  const editorRef = useRef(null);
  const actualTextWordCount = editorRef.current
    ? editorRef.current.plugins.wordcount.getCount()
    : 0;

  const isEmpty = (str) => !str?.length;
  const isPremium =
    textForm &&
    !isEmpty(textForm.url) &&
    !isEmpty(textForm.initialTextContent) &&
    orderType === 'PREMIUM';
  const hasInitialTextContent =
    textForm &&
    (!isEmpty(textForm.initialTextContent) ||
      !isEmpty(textForm.initialHtmlContent));
  const colWidth = isPremium || hasInitialTextContent ? 6 : 12;
  const stepNumber = isPremium || hasInitialTextContent ? 3 : 2;

  const handleDivClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setWebsites(e.target.value);
  };

  useEffect(() => {
    document.title = t('Typix - Détails du texte');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  const generateBillAdminForWriterCommand = async () => {
    try {
      await axiosFacturation.get(
        `/admin/GenerateBill?writer command=${textForm.writerCommandId}`
      );
      setIsSuccess(true);
    } catch (error) {
      setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
      setIsSuccess(false);
    }
  };
  useEffect(() => {
    if (textForm) {
      setTextTitle(textForm.textTitle);
      setkeyword(textForm.keyword);
      setUrl(textForm.url);
      setTitleTag(textForm.titleTag);
      setMetaDescriptionTag(textForm.metaDescriptionTag);
      setWebsites(textForm.internalLinking);
      setStuffedKeywords(textForm.stuffedKeywords);
      setInitialStuffedKeywords(textForm.initialStuffedKeywords);
      setActualTextContent(textForm.actualTextContent);
      setActualHtmlContent(textForm.actualHtmlContent);
      setInitialTextContent(textForm.initialTextContent);
      setInitialHtmlContent(textForm.initialHtmlContent);
      setAnalyseText(
        textForm.actualTextContent
          ? textForm.actualTextContent
          : textForm.actualHtmlContent
      );

      let result = textForm.analyseResult && JSON.parse(textForm.analyseResult);
      setAnalyseResult(
        result && typeof result === 'string' ? JSON.parse(result) : result
      );

      setKeywords(
        textForm.semanticGap
          ? extractKeywordsWithFrequency(textForm.semanticGap)
          : ''
      );
      setCombinedKeywords(
        textForm.semanticGap ? formatKeywordData(textForm.semanticGap) : ''
      );
      setCombinedKeywordsList(textForm.semanticGap);

      setList(
        textForm.semanticGap
          ? textForm.semanticGap.reduce((acc, curr) => {
              acc[curr.word] = curr.frequency;
              return acc;
            }, {})
          : {}
      );
      setThreadId(textForm.threadId);
      setIsTextVerified(textForm.verifiedByAdmin);
    }
  }, [isLoading, textForm]);

  useEffect(() => {
    const checkValidForm = () => {
      if (
        !textTitle ||
        !keyword ||
        (orderType !== 'SMART' && !titleTag) ||
        (orderType !== 'SMART' && !metaDescriptionTag) ||
        (textForm && textForm.initialTextContent && !url)
      ) {
        setDisabledForm(true);
        return;
      }

      setDisabledForm(false);
      return;
    };
    checkValidForm();
  }, [
    textForm,
    orderType,
    textTitle,
    keyword,
    titleTag,
    url,
    metaDescriptionTag,
    actualTextContent,
  ]);

  async function validateData(data, { reset }, event) {
    let action = event.nativeEvent.submitter.value;

    await checkDataAdmin(
      data,
      orderType,
      formRef,
      action,
      PutToDraft,
      DeliverText,
      EditText,
      InvalidateText,
      ReactivateInitialText,
      VerifyAndDeliverText,
      VerifyText
    );
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  useEffect(() => {
    let intervalId;

    const checkStatus = async () => {
      try {
        if (!isLoading && !isError) {
          const response = await axiosPrivate.get(
            `/texts/${textForm.id}/getTextRegenerationJobStatus`
          );

          if (response.data === 'PROCESSING') {
            setRegenerateLoading(true);
          }

          if (response.data === 'FAILED') {
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            setIsSuccess(false);
            setErrorSuccess(true);
            setRegenerateLoading(false);
            clearInterval(intervalId);
          }

          if (response.data === 'UNKNOWN') {
            setRegenerateLoading(false);
            clearInterval(intervalId);
          }

          if (response.data === 'COMPLETED') {
            clearInterval(intervalId);
            dispatch(fetchText(textForm.id));

            setRegenerateLoading(false);
          }
        }
      } catch (error) {
        console.error('Error checking API status:', error);

        clearInterval(intervalId);
      }
    };
    checkStatus();

    intervalId = setInterval(() => {
      checkStatus();
    }, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [startChecking]);

  const regenerateChatGPTText = async () => {
    try {
      const response = await axiosPrivate.post(
        `/texts/${textForm.id}/regenerateChatGPTText`
      );
      setJobStarted(response.data);
      setStartChecking(true);
    } catch (error) {
      console.error('Error generating text:', error);
    }
  };

  const PutToDraft = () => {
    const data = {
      actualHtmlContent,
      actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      keyword,
      metaDescriptionTag,
      semanticGap: list ? transformToSemanticGap(combinedKeywords) : null,
      internalLinking: websites,
      stuffedKeywords: stuffedKeywords,
      textTitle: textTitle,
      titleTag: titleTag,
      url: url,
    };
    axiosPrivate
      .put(
        `${API.putToDraft.baseUrl}${textForm.id}${API.putToDraft.suffix}`,
        data
      )
      .then((res) => {
        dispatch(updateForm(res.data));
        dispatch(updateTextForm({ key: 'delivered', value: false }));
        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'delivered',
            value: false,
          })
        );

        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'verifiedByAdmin',
            value: false,
          })
        );
        setErrorSuccessMsg(
          `${t('Le texte')} ${textForm.textTitle} ${t(
            'a été mis en brouillon'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/04_Contenu_validé.gif');

        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };
  const NotifierClientDuKeywordStuffing = () => {
    axiosStuffedDetector
      .post(`/texts/keywordStuffing/notifyClient`, textForm.id, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setErrorSuccessMsg(
          'un email est envoyé pour notfier le client du keyword stuffing existant'
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((err) => {
        setErrorSuccessMsg(
          "un erreur lors de l'envoi d'email pour notfier le client du keyword stuffing existant"
        );
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const ReactivateInitialText = () => {
    axiosPrivate
      .put(
        `${API.reactivateInitialText.baseUrl}${textForm.id}${API.reactivateInitialText.suffix}`
      )
      .then(() => {
        dispatch(updateTextForm({ key: 'activeInitialText', value: true }));
        setErrorSuccessMsg(
          `${t('Le texte initial de')} "${textForm.textTitle}" ${t(
            'a été activé'
          )}!`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const checkAllValidatedAndGenerateBill = async () => {
    try {
      const res = await axiosFacturation.get(
        `/admin/allDeliveredTexts?writer command=${textForm.writerCommandId}`
      );

      if (res.data === true) {
        setErrorSuccessMsg(`${t('Le dernier texte a été livré avec succès')}!`);
        setErrorSuccessImg(animationUrl + '/06_Commande_livrée.gif');
        generateBillAdminForWriterCommand();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const VerifyText = () => {
    return axiosPrivate
      .put(
        `${API.verifyTextBeforeDeliver.baseUrl}${textForm.id}${API.verifyTextBeforeDeliver.suffix}`
      )
      .then((res) => {
        dispatch(updateForm(res.data));
        dispatch(updateTextForm({ key: 'verifiedByAdmin', value: true }));
        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'verifiedByAdmin',
            value: true,
          })
        );

        setErrorSuccessMsg(
          `${t('Le texte')} "${textForm.textTitle}" ${t(
            'a été vérifié avec succès'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/05_Contenu_livré.gif');
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(console.log('error'));
  };

  const DeliverText = () => {
    const list = KeyWordListFormatter(keywords);
    axiosPrivate
      .put(
        `${API.updateAndDeliver.baseUrl}${textForm.id}${API.updateAndDeliver.suffix}`,
        {
          actualHtmlContent,
          actualTextContent,
          analyseResult: analyseResult && JSON.stringify(analyseResult),
          keyword,
          metaDescriptionTag,
          semanticGap: list ? transformToSemanticGap(combinedKeywords) : null,
          textTitle,
          titleTag,
          totalNumberOfTextAdded: actualTextContent
            ? actualTextWordCount - initialTextWordCount
            : actualTextWordCount,
          url,
          stuffedKeywords: stuffedKeywords,
          internalLinking: websites,
        }
      )
      .then((res) => {
        dispatch(updateForm(res.data));

        dispatch(updateTextForm({ key: 'delivered', value: true }));
        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'delivered',
            value: true,
          })
        );
        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'keyword',
            value: keyword,
          })
        );
        setErrorSuccessMsg(
          `${t('Le texte')} "${textForm.textTitle}" ${t(
            'a été livré avec succès'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/05_Contenu_livré.gif');
        checkAllValidatedAndGenerateBill();
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            setErrorSuccessMsg(
              t(
                'Veuillez changer les informations suivantes: titre et/ou mot clé'
              )
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const VerifyAndDeliverText = async () => {
    await VerifyText();
    DeliverText();
  };

  const EditText = async () => {
    //setIsFetchLoading(true);
    const isKeywordsValid =
      textType !== 'TRANSLATED' ? await isValidFormat(combinedKeywords) : {};
    if (!isKeywordsValid && orderContentType !== 'GMB') {
      setErrorSuccessMsg(
        ` ${t(
          'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }
    const data = {
      actualHtmlContent,
      actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      keyword,
      metaDescriptionTag,
      semanticGap: list ? transformToSemanticGap(combinedKeywords) : null,
      internalLinking: websites,
      stuffedKeywords: stuffedKeywords,
      textTitle: textTitle,
      titleTag: titleTag,
      url: url,
    };

    axiosPrivate
      .put(`/texts/${textForm.id}/admin-edit`, data)
      .then((res) => {
        setIsFetchLoading(false);
        dispatch(updateForm(res.data));

        setErrorSuccessMsg(`${t('Le texte a été enregistré avec succès')}!`);
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setIsFetchLoading(false);

        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const InvalidateText = () => {
    axiosPrivate
      .put(`/texts/${textForm.id}/admin-invalidate`)
      .then((res) => {
        dispatch(updateForm(res.data));
        dispatch(updateTextForm({ key: 'validated', value: false }));

        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'validated',
            value: false,
          })
        );
        setErrorSuccessMsg(`${t('Le statut du contenu a bien été modifié')}!`);
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setIsFetchLoading(false);

        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const handleEditorInit = (value, editor) => {
    editorRef.current = editor;
    setEditorInitialized(true);
  };

  const handleTextEditorChange = (value, editor) => {
    let textValue = editor.getContent({ format: 'text' });

    if (!textForm.validated) {
      scheduleSave(
        textValue,
        value,
        textForm,
        initialHtmlContent,
        initialTextContent,
        orderType
      );
    }

    if (orderType !== 'PREMIUM') {
      setActualTextContent(value);
      setActualHtmlContent(value);

      setAnalyseText(textValue);
    } else {
      setActualHtmlContent(value);

      setActualTextContent(textValue);
    }

    const content =
      orderType === 'PREMIUM' ? actualHtmlContent : actualTextContent;

    const text = orderType === 'PREMIUM' ? actualTextContent : analyseText;
    const setContent = (value) => {
      if (orderType === 'PREMIUM') {
        setActualHtmlContent(value);
      } else {
        setActualTextContent(value);
      }
    };
  };

  const renderDeliveryButtons = () => {
    return (
      <>
        {/* 'Bon pour livraison' button - always visible */}
        {disabledForm || textForm.delivered ? (
          <button
            title={t('Mettre un texte en brouillon en livré')}
            className="bt-submit-disabled-y me-2"
          >
            {t('Bon pour livraison')}
          </button>
        ) : (
          <button
            title={t('Mettre un texte en brouillon en livré')}
            type="submit"
            className="bt-submit-y me-2"
            name="action"
            value="DeliverText"
            style={{ background: '#43B929', color: 'white' }}
          >
            {t('Bon pour livraison')}
          </button>
        )}

        {/* 'Bon pour Livraison Vérifié' button - conditionally visible */}
        {disabledForm || (textForm.verifiedByAdmin && textForm.delivered) ? (
          <button
            title={t('Mettre un texte en brouillon en livré verifié')}
            className="bt-submit-disabled-y me-2"
          >
            {t('Bon pour Livraison Vérifié')}
          </button>
        ) : !textForm.verifiedByAdmin && !textForm.delivered ? (
          <button
            title={t('Mettre un texte en brouillon en livré verifié')}
            type="submit"
            className="bt-submit-y me-2"
            name="action"
            value="VerifyAndDeliverText"
            style={{ background: '#43B929', color: 'white' }}
          >
            {t('Bon pour Livraison Vérifié')}
          </button>
        ) : (
          !textForm.verifiedByAdmin &&
          textForm.delivered &&
          !textForm.validated && (
            <button
              title={t('Vérifier le texte')}
              type="submit"
              className="bt-submit-y me-2"
              name="action"
              value="VerifyText"
              style={{ background: '#43B929', color: 'white' }}
            >
              {t('Vérifié')}
            </button>
          )
        )}
      </>
    );
  };

  if (isFetchLoading || isLoading || isError) return <Loader />;

  return (
    <div className="content-center">
      <Form ref={formRef} onSubmit={validateData}>
        <div className="content-center">
          <div className="box-header-top header-info">
            <span className="bt-circle me-3" onClick={history.goBack}></span>
            <h1>{textTitle}</h1>
            {orderType !== 'PREMIUM' ? (
              <h2
                onClick={regenerateChatGPTText}
                className={
                  regenerateLoading ? 'bt-reload-dynamic' : 'bt-reload-static'
                }
              >
                {' '}
              </h2>
            ) : (
              ''
            )}
          </div>

          <div className="row">
            <div className="col-12 col-md-12">
              <div className="box-wrapper-gray">
                <div className="box-txt">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <strong>{t('Détails du texte')}</strong>{' '}
                    {t('(Titre, mot clé, url, balise, méta-description)')}
                  </div>
                  <div
                    className="i-13"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <i
                      className={`fa fa-chevron-${expanded ? 'up' : 'down'}`}
                    />
                  </div>
                </div>
                {expanded ? (
                  <>
                    <div className="row row-mb">
                      <div className="col-12 col-md-6">
                        <div className="label-title">{t('Nom du texte')}*</div>
                        <div>
                          <InputField
                            name="textTitle"
                            type="text"
                            className="form-control form-ct"
                            placeholder={t('Nom du texte')}
                            value={textTitle}
                            onChange={(e) => setTextTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="label-title">{t('Mot clé')}*</div>
                        <div>
                          <InputField
                            name="keyword"
                            type="text"
                            className="form-control form-ct"
                            placeholder={t('Mot clé')}
                            value={keyword}
                            onChange={(e) => setkeyword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-mb">
                      <div className="col-12 col-md-6">
                        <div className="label-title">{t('URL')}</div>
                        <div style={{ position: 'relative' }}>
                          <InputField
                            name="url"
                            type="text"
                            className="form-control form-ct"
                            placeholder="https://www."
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            style={{ position: 'relative' }}
                          />
                          <ClipboardCopy url={url} />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="label-title">{t('Balise titre')}*</div>
                        <div>
                          <InputField
                            name="titleTag"
                            type="text"
                            className="form-control form-ct"
                            placeholder={t('Balise titre')}
                            value={titleTag}
                            onChange={(e) => setTitleTag(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="label-title">
                          {t('Balise meta-description')}*
                        </div>
                        <div>
                          <InputField
                            name="metaDescriptionTag"
                            type="text"
                            className="form-control form-ct"
                            placeholder={t('Balise meta-description')}
                            value={metaDescriptionTag}
                            onChange={(e) =>
                              setMetaDescriptionTag(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {(textType === 'NON_TRANSLATED' || textType === 'COMBINED') && (
                <>
                  <div className="mt-3">
                    <div className="label-title label-text-h">
                      <strong>{t('Étape')} 1</strong> -{' '}
                      {t('Insérez votre gap sémantique avec l’occurrence')}:
                      <CustomTooltip
                        title={
                          <div style={{ marginLeft: '10px' }}>
                            <div className="row">{t('Format souhaité')} : </div>
                            <div className="row">
                              {t('mot-clé (fréquence)')}
                            </div>
                            <div className="row">
                              {t('Séparez les mots-clés par un Enter .')}
                            </div>
                            <div className="row">
                              ---------------------------------
                            </div>
                            <div className="row">{t('Exemple')} :</div>
                            <div className="row">{t('mot-clé')}1 (2)</div>
                            <div className="row">{t('mot-clé')}2 (1)</div>
                            <div className="row">{t('mot-clé')}3 (1)</div>
                            <div className="row">{t('mot-clé')}4 (2)</div>
                          </div>
                        }
                        placement="top"
                      >
                        <button
                          type="button"
                          className="btn-circle"
                          style={{ marginLeft: 5 }}
                        ></button>
                      </CustomTooltip>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        (stuffedKeywords && stuffedKeywords.length > 0) ||
                        (initialStuffedKeywords &&
                          initialStuffedKeywords.length > 0)
                          ? 'col-12 col-md-2'
                          : 'col-12 col-md-3'
                      }
                    >
                      <div className="d-flex flex-column justify-content-end">
                        <div className="box-wrapper">
                          {autoKeywordsGenerationLoading ? (
                            <Loader />
                          ) : (
                            <textarea
                              className=" col-12  box-list-text"
                              placeholder={t(
                                'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'
                              )}
                              value={combinedKeywords}
                              onChange={(e) =>
                                setCombinedKeywords(e.target.value)
                              }
                            ></textarea>
                          )}
                        </div>
                        {orderContentType !== 'GMB' && (
                          <div className="d-flex justify-content-end">
                            <button
                              className="bt-gap-generation"
                              onClick={() => {
                                keywordsAutoGeneration(
                                  orderId,
                                  keyword,
                                  orderSiloName,
                                  url,
                                  axiosPrivate,
                                  axiosMonetoring,
                                  transformData,
                                  formatKeywordData,
                                  extractKeywordsWithFrequency,
                                  setKeywords,
                                  setCombinedKeywords,
                                  setCombinedKeywordsList,
                                  setWebsites,
                                  setErrorSuccessMsg,
                                  setIsSuccess,
                                  setErrorSuccess,
                                  t,
                                  setAutokeywordGenerationLoading
                                );
                              }}
                            >
                              {t('Récupérer GAP et/ou maillage')}
                            </button>{' '}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={'col-12 col-md-3 box-wrapper '}
                      style={{
                        overflow: 'hidden',
                        wordWrap: 'break-word',
                      }}
                      onClick={handleDivClick}
                    >
                      <InternalLinksBox
                        isEditing={isEditing}
                        websites={websites}
                        actualHtmlContent={actualHtmlContent}
                        setIsEditing={setIsEditing}
                        handleInputChange={handleInputChange}
                        t={t}
                      />
                    </div>

                    <div className="col-12 col-md-3">
                      <div
                        className="box-wrapper box-list-text"
                        style={{ height: '284px; !important' }}
                      >
                        <div className="header-title mb-0">
                          {t('Résultat sémantique')}
                        </div>
                        <div className="sub-header-title">
                          {t('Enrichissement sémantique')} :
                        </div>
                        <div className="box-graph">
                          {loading && <Loader />}
                          {analyseResult &&
                          analyseResult.semanticEnrichment >= 0 ? (
                            <div className="d-flex justify-content-center">
                              <CustomHighcharts
                                value={analyseResult.semanticEnrichment}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <button
                            className="bt-submit-y"
                            style={{ margin: '0 auto' }}
                            onClick={() =>
                              DownloadOneTextWordType(textForm.id, user)
                            }
                          >
                            {t('Télécharger le document')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        (stuffedKeywords && stuffedKeywords.length > 0) ||
                        (initialStuffedKeywords &&
                          initialStuffedKeywords.length > 0)
                          ? 'col-12 col-md-2'
                          : 'col-12 col-md-3'
                      }
                    >
                      <div className="box-wrapper">
                        <div className="header-title mb-0">
                          {t('Mots clés manquants')} :
                        </div>
                        <br />
                        <div className="box-list-text box-motcle">
                          {loading && <Loader />}
                          <ul>
                            {analyseResult &&
                            analyseResult.greenWordsByFreq &&
                            analyseResult.greenWordsByFreq
                              .greenWordsWithRemainingFreq &&
                            Object.keys(
                              analyseResult.greenWordsByFreq
                                .greenWordsWithRemainingFreq
                            ).length > 0 ? (
                              <div>
                                <b>
                                  {t(
                                    'Ajout de mots-clés avec la fréquence restante'
                                  )}{' '}
                                  :{' '}
                                </b>
                                <p>
                                  {Object.keys(
                                    analyseResult.greenWordsByFreq
                                      .greenWordsWithRemainingFreq
                                  ).map((item, key) => (
                                    <li key={key}>
                                      {' '}
                                      <span>
                                        {' '}
                                        {item} (
                                        <span
                                          style={{
                                            textDecorationLine: 'line-through',
                                          }}
                                        >
                                          {list[item]}
                                        </span>
                                        ,
                                        {
                                          analyseResult.greenWordsByFreq
                                            .greenWordsWithRemainingFreq[item]
                                        }
                                        ){' '}
                                      </span>{' '}
                                    </li>
                                  ))}
                                </p>
                              </div>
                            ) : null}
                            {analyseResult &&
                            analyseResult.remainingKeywords &&
                            Object.keys(analyseResult.remainingKeywords)
                              .length > 0 ? (
                              <div>
                                <b>{t('Mots clés restants')} : </b>
                                <p>
                                  {Object.keys(
                                    analyseResult.remainingKeywords
                                  ).map((item, key) => (
                                    <li key={key}>
                                      {' '}
                                      <span>
                                        {' '}
                                        {item} (
                                        {analyseResult.remainingKeywords[item]}){' '}
                                      </span>{' '}
                                    </li>
                                  ))}
                                </p>
                              </div>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {((stuffedKeywords && stuffedKeywords.length > 0) ||
                      (initialStuffedKeywords &&
                        initialStuffedKeywords.length > 0)) && (
                      <div className="col-12 col-md-2">
                        <div className="box-wrapper box-list-text">
                          <div className="header-title mb-0">
                            {t('Mots clés Bourrées')} :
                          </div>
                          <br />
                          <div className="box-list-text box-motcle">
                            {keywordStuffingLoading && <Loader />}
                            <ul>
                              {!keywordStuffingLoading &&
                                stuffedKeywords &&
                                stuffedKeywords.length > 0 && (
                                  <div>
                                    <b>
                                      {t(
                                        'Mots clés à haute densité dans le texte actuel'
                                      )}{' '}
                                      :{' '}
                                    </b>
                                    <p>
                                      {stuffedKeywords.map((item) => {
                                        const tfPercentage =
                                          item['tfPercentage'] * 100;
                                        let colorClass;

                                        if (tfPercentage > 2) {
                                          colorClass = 'keywordStuffedRed';
                                        } else if (
                                          tfPercentage > 1.5 &&
                                          tfPercentage <= 2
                                        ) {
                                          colorClass = 'keywordStuffedOrange';
                                        } else {
                                          colorClass = 'keywordStuffedYellow';
                                        }

                                        return (
                                          <li
                                            key={item['word']}
                                            className={colorClass}
                                          >
                                            <span> {item['word']} </span> (
                                            <span>
                                              {tfPercentage.toFixed(2)}%{' '}
                                            </span>
                                            )
                                          </li>
                                        );
                                      })}
                                    </p>
                                  </div>
                                )}
                              {!keywordStuffingLoading &&
                                initialStuffedKeywords &&
                                initialStuffedKeywords.length > 0 && (
                                  <div>
                                    <b>
                                      {t(
                                        'Mots clés à haute densité dans le texte initial'
                                      )}{' '}
                                      :{' '}
                                    </b>
                                    <p>
                                      {initialStuffedKeywords.map((item) => {
                                        const tfPercentage =
                                          item['tfPercentage'] * 100;
                                        let colorClass;

                                        if (tfPercentage > 2) {
                                          colorClass = 'keywordStuffedPurple';
                                        }
                                        return (
                                          <li
                                            key={item['word']}
                                            className={colorClass}
                                          >
                                            <span> {item['word']} </span> (
                                            <span>
                                              {tfPercentage.toFixed(2)}%{' '}
                                            </span>
                                            )
                                          </li>
                                        );
                                      })}
                                    </p>
                                  </div>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {(textForm.globalRevisionSent || textForm.revisionSent) &&
                textForm.revisionText && (
                  <RevisionDetails
                    t={t}
                    textId={textForm.id}
                    revisionRequestAt={textForm.revisionRequestAt}
                    revisionText={textForm.revisionText}
                    revisionFileName={textForm.revisionFileName}
                  />
                )}
              <div className="row mt-2">
                {hasInitialTextContent || isPremium ? (
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="label-title label-text-h">
                        <strong>
                          {t('Étape')} {textType === 'TRANSLATED' ? '1' : '2'}
                        </strong>{' '}
                        -{' '}
                        {t('Coller le texte initial dans le champs ci-dessous')}
                        <span className="txt-gray">
                          : (
                          {textType === 'TRANSLATED'
                            ? t('obligatoire')
                            : t('optionel')}
                          )
                        </span>
                      </div>

                      <div className="box-wrapper-editor">
                        <CustomEditor
                          isReadOnly={true}
                          content={textForm.initialHtmlContent}
                          onInit={(value, editor) =>
                            setInitialTextWordCount(
                              editor.plugins.wordcount.getCount()
                            )
                          }
                          t={t}
                        />
                      </div>

                      <div className="box-botom-flex">
                        <div style={{ paddingLeft: 10 }}>
                          <div className="box-list-dropdown">
                            {t('Langue')} :
                            <div
                              className="dropdown"
                              style={{ width: 170, marginLeft: 5 }}
                            >
                              <button
                                className="btn dropdown-toggle dropdown-custom w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {t(capitalize(language.language.toLowerCase()))}
                                <span>
                                  <ChevronDownIcon />
                                </span>
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {' '}
                                {ANALYSELANGAUGESLIST.map((item, index) => (
                                  <li key={index}>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => setLanguage(item)}
                                    >
                                      {t(
                                        capitalize(item.language.toLowerCase())
                                      ).toUpperCase()}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div>
                          {textForm.activeInitialText ? (
                            <button className="bt-submit-disabled-y">
                              {t('Réactiver')}
                            </button>
                          ) : (
                            <button
                              className="bt-submit-y"
                              type="submit"
                              name="action"
                              value="ReactivateInitialText"
                            >
                              {t('Réactiver')}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className={`col-12 col-md-${colWidth}`}>
                  <div>
                    <div className="label-title label-text-h">
                      <strong>
                        {t('Étape')}{' '}
                        {textType === 'TRANSLATED' ? '2' : stepNumber}
                      </strong>{' '}
                      - {t('Ajouter ou compléter ci-dessous votre contenu')} :
                    </div>

                    {/* Text Component */}

                    <div className="box-wrapper-editor">
                      {regenerateLoading && <Loader />}
                      <CustomEditor
                        disabled={regenerateLoading}
                        content={
                          orderType !== 'PREMIUM'
                            ? actualTextContent
                            : actualHtmlContent
                        }
                        onInit={handleEditorInit}
                        onChange={handleTextEditorChange}
                        t={t}
                        threadId={threadId}
                        assistantId={assistantId}
                        orderType={orderType}
                        orderContentType={orderContentType}
                      />
                    </div>
                    <SavingIndicator isSaving={isSaving} />

                    <div className="row">
                      <div
                        className={`col-${colWidth === 6 ? 'md-12' : 'md-6'}`}
                      >
                        {/* Text word count */}
                        {!isLoading ? (
                          <div className="box-detail-text">
                            {textType === 'NON_TRANSLATED' && (
                              <div>
                                {t(
                                  'Nombre total de mots (existants et ajoutés)'
                                )}{' '}
                                :{actualTextWordCount}{' '}
                              </div>
                            )}
                            <div>
                              {t('Nombre de mot à ajouter')} (Min/Max) :
                              {textForm.minWords}/{textForm.maxWords}
                            </div>
                            <div>
                              {t('Nombre total de mots ajoutés')} :
                              {textType === 'NON_TRANSLATED' ? (
                                <DisplayTextAmountColor
                                  initialTextWordCount={initialTextWordCount}
                                  actualTextWordCount={actualTextWordCount}
                                  minWords={textForm.maxWords}
                                  maxWords={textForm.minWords}
                                />
                              ) : (
                                <span
                                  style={
                                    {
                                      // color:
                                      //   actualTextWordCount > maxWords
                                      //     ? '#FF8D4B'
                                      //     : actualTextWordCount < minWords
                                      //     ? '#EE3900'
                                      //     : '#3c3c3c',
                                    }
                                  }
                                >
                                  {actualTextWordCount}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {colWidth === 12 && (
                        <div className="col-md-6  d-flex justify-content-end">
                          {/* Language Dropdown */}
                          <div className="box-list-dropdown mt-2">
                            {t('Langue')} :
                            <div
                              className="dropdown"
                              style={{ width: 170, marginLeft: 5 }}
                            >
                              <button
                                className="btn dropdown-toggle dropdown-custom w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {t(capitalize(language.language.toLowerCase()))}
                                <span>
                                  <ChevronDownIcon />
                                </span>
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {ANALYSELANGAUGESLIST.map((item, index) => (
                                  <li key={index}>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => setLanguage(item)}
                                    >
                                      {t(
                                        capitalize(item.language.toLowerCase())
                                      ).toUpperCase()}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex ">
                      <div style={{ flex: 1 }}>
                        <div
                          className={`d-inline-flex button-stack ${
                            colWidth === 6 ? 'vertical-layout' : ''
                          }`}
                        >
                          {renderDeliveryButtons()}
                          <span className="me-2">
                            {initialStuffedKeywords &&
                              initialStuffedKeywords.length > 0 &&
                              !textForm.delivered && (
                                <button
                                  title={t(
                                    'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing'
                                  )}
                                  className="bt-submit-y"
                                  onClick={NotifierClientDuKeywordStuffing}
                                  style={{
                                    marginLeft: '10px',
                                    background: '#43B929',
                                    color: 'white',
                                  }}
                                >
                                  {t('Informer client')}
                                </button>
                              )}
                          </span>
                          {textForm?.validated && (
                            <button
                              title={t('Remettre un texte validé à livré')}
                              type="submit"
                              className="bt-submit-y me-2"
                              name="action"
                              value="InvalidateText"
                            >
                              {t('Invalider le texte')}
                            </button>
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="d-inline-flex">
                          {textType !== 'TRANSLATED' && (
                            <>
                              {!isEmpty(textForm.url) &&
                              !isEmpty(textForm.initialTextContent) ? (
                                <span className="me-2">
                                  <button
                                    className="bt-submit-y"
                                    onClick={() => setShowComparison(true)}
                                  >
                                    {t('Comparer')}
                                  </button>{' '}
                                </span>
                              ) : (
                                ''
                              )}

                              <span className="me-2">
                                <AnalyseText
                                  orderType={orderType}
                                  keywords={keywords}
                                  initialText={textForm.initialTextContent}
                                  text={
                                    orderType === 'PREMIUM'
                                      ? actualTextContent
                                      : analyseText
                                  }
                                  content={
                                    orderType === 'PREMIUM'
                                      ? actualHtmlContent
                                      : actualTextContent
                                  }
                                  setContent={(value) => {
                                    if (orderType === 'PREMIUM') {
                                      setActualHtmlContent(value);
                                    } else {
                                      setActualTextContent(value);
                                    }
                                  }}
                                  language={language}
                                  setList={setList}
                                  setResult={(value) => setAnalyseResult(value)}
                                  setLoading={(value) => setLoading(value)}
                                  setIsAutomaticTextAnalysis={(value) =>
                                    setIsAutomaticTextAnalysis(value)
                                  }
                                  isAutomaticTextAnalysis={
                                    isAutomaticTextAnalysis
                                  }
                                />
                              </span>
                            </>
                          )}

                          <span className="me-2">
                            {textForm.delivered && !textForm.validateAt ? (
                              <button
                                type="submit"
                                className="bt-submit-y"
                                name="action"
                                value="PutToDraft"
                              >
                                {t('Brouillon')}
                              </button>
                            ) : (
                              <button className="bt-submit-disabled-y">
                                {t('Brouillon')}
                              </button>
                            )}
                          </span>

                          <span>
                            {!textForm.validateAt ? (
                              <button
                                type="submit"
                                className="bt-submit-y"
                                name="action"
                                value="EditText"
                              >
                                {t('Editer')}
                              </button>
                            ) : (
                              <button className="bt-submit-disabled-y">
                                {t('Editer')}
                              </button>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>

      <ComparisonModal
        open={showComparison}
        initialText={textForm.initialTextContent}
        text={he.decode(actualTextContent)}
        handleClose={() => setShowComparison(false)}
      />
      {errorSuccessMsg && (
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => setErrorSuccess(false)}
          success={isSuccess}
          imageUrl={errorSuccessImg}
        />
      )}
    </div>
  );
}
