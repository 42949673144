import React, { useState } from 'react';
import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const CustomSearchBox = ({
  searchValue,
  setSearchValue,
  elementsPerPage,
  setElementsPerPage,
  pageNbOptions,
  t,
}) => {
  return (
    <div>
      <div className="box-list-ui">
        <div className="box-input-text pe-0">
          <div className="input-group input-cs w-100 pe-0">
            <div className="input-group-text">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="form-control ps-0"
              value={searchValue}
              placeholder={t('Rechercher')}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchBox;
