import React from 'react';
import InternalLinksList from './InternalLinksList';
import { formatwebsiteUrls } from 'helpers/FormatKeywordsHelper';

const InternalLinksBox = ({
  isEditing,
  websites,
  actualHtmlContent,
  setIsEditing,
  handleInputChange,
  t,
}) => {
  return (
    <>
      {isEditing ? (
        <textarea
          className=" col-12  box-list-text box-transparent"
          placeholder="https//www.websites.com"
          value={formatwebsiteUrls(websites)}
          onChange={handleInputChange}
          onBlur={() => setIsEditing(false)}
          style={{ color: 'black' }}
        />
      ) : (
        <InternalLinksList
          websites={websites}
          actualHtmlContent={actualHtmlContent}
          t={t}
        />
      )}
    </>
  );
};

export default InternalLinksBox;
