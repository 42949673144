export const PAGENUMBERSLARGELIMIT = 4;

export const PAGENUMBERSTINYLIMIT = 3;

export const pageNbOptions = [10, 15, 20, 50, 100];
export const sentenceSeparators = [
  '.',
  '?',
  '!',
  '...',
  ',',
  ';',
  ':',
  '(',
  ')',
  '[',
  ']',
  '«',
  '»',
  '"',
  '-',
];

export const MAIN_LANGUAGES = [
  'Français',
  'Anglais',
  'Espagnol',
  'Italien',
  'Allemand',
];

export const LANGUAGES = [
  { text: 'en', img: 'Images/img_flag/GB_flag.png' },
  { text: 'fr', img: 'Images/img_flag/FR_flag.png' },
  { text: 'de', img: 'Images/img_flag/DE_flag.png' },
  { text: 'es', img: 'Images/img_flag/ES_flag.png' },
  { text: 'it', img: 'Images/img_flag/IT_flag.png' },
  { text: 'da', img: 'Images/img_flag/DA_flag.png' },
  { text: 'fi', img: 'Images/img_flag/FI_flag.png' },
  { text: 'nb', img: 'Images/img_flag/NB_flag.png' },
  { text: 'nl', img: 'Images/img_flag/NL_flag.png' },
  { text: 'pl', img: 'Images/img_flag/PL_flag.png' },
  { text: 'pt', img: 'Images/img_flag/PT_flag.png' },
  { text: 'ro', img: 'Images/img_flag/RO_flag.png' },
  { text: 'sv', img: 'Images/img_flag/SV_flag.png' },
  { text: 'el', img: 'Images/img_flag/GK_flag.png' },
  { text: 'sk', img: 'Images/img_flag/SK_flag.png' },
  { text: 'cs', img: 'Images/img_flag/CS_flag.png' },
];

export const BILL_STATUS_PAID = 'PAID';
export const BILL_STATUS_PAYMENT = 'PAYMENT';
export const BILL_MODIFICATION = 'MODIFICATION';
export const BILL_MODIFIED = 'MODIFIED';
export const BILL_STATUS_VALIDATION = 'VALIDATION';
export const BILL_STATUS_NOTGENERATED = 'NOTGENERATED';
export const BILL_TRANSFERRED = 'TRANSFERRED';
export const DEMAND_MODIFICATION = 'MODIFY';

export const INSTRUCTIONS = 'INSTRUCTIONS';
export const WRITING = 'WRITING';
export const DEADLINE = 'DEADLINE';

export const ROLES = {
  ADMIN: 'ADMIN',
  WRITER: 'WRITER',
  CLIENT: 'CLIENT',
  CONSULTANT: 'CONSULTANT',
};

export const ORDERSTATUS = {
  VALID: 'VALID',
  DELAYED: 'DELAYED',
  INPROGRESS: 'INPROGRESS',
  NONATTRIBUTED: 'NONATTRIBUTED',
  SMART_WITH_REVIEW: 'SMART_WITH_REVIEW',
  SMART: 'SMART',
};

export const NOTIFICATION_TYPE = {
  REVISION_DONE: 'REVISION_DONE',
  TEXT_IS_DELIVERED: 'TEXT_IS_DELIVERED',
  COMMAND_READY: 'COMMAND_READY',
  REVISION_REQUEST: 'REVISION_REQUEST',
  WRITER_APPLIED: ' WRITER_APPLIED',
  ALL_TEXTS_DELIVERED: 'ALL_TEXTS_DELIVERED',
  COMMAND_VALIDATED: 'COMMAND_VALIDATED',
  COMMAND_CREATED: 'COMMAND_CREATED',
  APPLICATION_ACCEPTED: 'APPLICATION_ACCEPTED',
};

export const WRITER_VALIDATED_ORDERS_COLUMN = [
  {
    label: 'Titre de la commande',
    field: 'title',
    sortable: true,
  },
  { label: 'Type', field: 'type', sortable: false },
  { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'validatedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
];

export const BILL_COMLUMNS = [
  {
    label: 'Titre de la commande',
    field: 'commandTitle',
    sortable: true,
  },

  { label: 'Montant estimé', field: 'totalPrice', sortable: true },
  { label: 'Statut', field: 'status', sortable: true },
  {
    label: 'Date',
    field: 'validatedTime',
    sortable: true,
  },
  {
    label: 'Action',
    field: 'action',
    sortable: false,
  },
];
export const BILL_COMLUMNS_ADMIN = [
  {
    label: 'Titre de la commande',
    field: 'commandTitle',
    sortable: true,
  },
  { label: 'Rédacteur', field: 'writerName', sortable: true },

  { label: 'Montant estimé', field: 'totalPrice', sortable: true },
  { label: 'Statut', field: 'status', sortable: true },
  {
    label: 'Date',
    field: 'validatedTime',
    sortable: true,
  },
  {
    label: 'Action',
    field: 'action',
    sortable: false,
  },
];

export const NON_ATTRUBUTED_ORDERS_COLUMN = [
  {
    label: 'Titre de la commande',
    field: 'title',
    sortable: false,
  },
  { label: 'Thématiques', field: 'thematics', sortable: false },
  {
    label: 'Nb total de textes',
    field: 'totalTextNb',
    sortable: false,
  },
  {
    label: 'Postuler',
    field: 'apply',
    sortable: false,
  },
  {
    label: 'Supprimer',
    field: 'delete',
    sortable: false,
  },
];

export const CLIENT_ORDERS_COLUMN = [
  {
    label: 'Titre de la commande',
    field: 'title',
    sortable: true,
    sort: 'asc',
  },
  { label: 'Type', field: 'type', sortable: false },
  {
    label: 'Livraison estimée',
    field: 'deadline',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'validatedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes en ligne',
    field: 'deployedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
  {
    label: '',
    field: 'selected',
    sortable: false,
  },
  {
    label: '',
    field: 'downloadAll',
    sortable: false,
  },
];

export const CONSULTANT_ORDERS_COLUMN = [
  {
    label: 'Titre de la commande',
    field: 'title',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Deadline',
    field: 'deadline',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },

  {
    label: 'Textes validés',
    field: 'validatedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes en ligne',
    field: 'deployedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
];

export const ADMIN_REVIEW_ORDERS_COLUMNS = [
  { label: 'Titre de la commande', field: 'title', sortable: true },
  { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },
  { label: 'Rédacteurs', field: 'writers', sortable: false },
  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'validatedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes en ligne',
    field: 'deployedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Temps restant',
    field: 'finishedAt',
    sortable: true,
    sort: 'asc',
  },
];

export const FILTER_ORDER_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'Rédaction premium', type: 'PREMIUM' },
  { text: 'Rédaction avec révision', type: 'SMART_WITH_REVIEW' },
  { text: 'Rédaction intelligente', type: 'SMART' },
];

export const FILTER_WRITER_ORDER_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'Rédaction premium', type: 'PREMIUM' },
  { text: 'Rédaction avec révision', type: 'SMART_WITH_REVIEW' },
];

export const FILTER_CLIENT_ORDER_TYPE_LIST = [...FILTER_WRITER_ORDER_TYPE_LIST];

export const ORDER_CONTENT_TYPE = [
  { name: 'Fiche produit courte', type: 'PRODUCT' },
  { name: 'Fiche produit longue', type: 'LONG_PRODUCT' },
  { name: 'GMB', type: 'GMB' },
  { name: 'STORE LOCATOR', type: 'STORE_LOCATOR' },
  { name: 'Netlinking', type: 'NETLINKING' },
  { name: 'Autre', type: 'OTHER' },
  { name: 'Category', type: 'LONG_CATEGORY' },
  { name: 'Content enrichment', type: 'CONTENT_IMPROVEMENT' },
];

export const ORDER_TYPE = [
  { name: 'Premium', type: 'PREMIUM' },
  { name: 'Smart + review', type: 'SMART_WITH_REVIEW' },
  { name: 'Smart', type: 'SMART' },
];

export const PAGE_NB_OPTIONS = [10, 15, 20, 50, 100];

export const ANALYSELANGAUGESLIST = [
  {
    id: 'FRENCH',
    language: 'FRANÇAIS',
  },
  {
    id: 'GERMAN',
    language: 'ALLEMAND',
  },
  {
    id: 'ENGLISH',
    language: 'ANGLAIS',
  },
  {
    id: 'BULGARIAN',
    language: 'BULGARE',
  },

  {
    id: 'KOREAN',
    language: 'CORÉEN',
  },
  {
    id: 'SPANISH',
    language: 'ESPAGNOL',
  },
  {
    id: 'ESTONIAN',
    language: 'ESTONIEN',
  },
  {
    id: 'FINNISH',
    language: 'FINNOIS',
  },
  {
    id: 'GREEK',
    language: 'GREC',
  },
  {
    id: 'ITALIAN',
    language: 'ITALIEN',
  },
  {
    id: 'DUTCH',
    language: 'NÉERLANDAIS',
  },
  {
    id: 'POLISH',
    language: 'POLISH',
  },
  {
    id: 'PORTUGUESE',
    language: 'PORTUGAIS',
  },
  {
    id: 'ROMANIAN',
    language: 'ROUMAIN',
  },
  {
    id: 'RUSSIAN',
    language: 'RUSSE',
  },
  {
    id: 'CZECH',
    language: 'CZECH',
  },
];
export const filterTextByStatus = [
  { text: 'Tous les textes', field: '' },
  { text: 'Textes validés', field: 'validated', value: true },
  { text: 'Textes revisés', field: 'revisionDone', value: true },
  { text: 'Révision en attente', field: 'revisionSent', value: true },
  { text: 'Textes livrés', field: 'delivered', value: true },
  { text: 'Brouillons', field: 'brouillon', value: true },
  { text: 'Contenu en ligne', field: 'deployed', value: true },
];

export const filterTextByType = [
  { text: 'Rédaction', field: 'NON_TRANSLATED' },
  { text: 'Traduction', field: 'TRANSLATED' },
  { text: 'Rédaction+Traduction', field: 'COMBINED' },
];

export const DefaultLanguage = 'fr';

export const SavingState = Object.freeze({
  NOT_SAVED: 0,
  SAVING: 1,
  SAVED: 2,
});

export const KeywordStuffingModalPrompts = {
  actualTextKeywordStuffingTitle:
    'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ',
  initialTextKeywordStuffingTitle:
    'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.',
  actualTextKeywordStuffingMsg:
    'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.',
  initialTextKeywordStuffingMSg:
    'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.',
  successDemandeRevisionKeywordStuffing: 'Votre demande a été bien envoyée !',
  errorDemandeRevisionKeywordStuffing:
    "Il y a eu un problème lors de l'envoi de votre demande",
  waitingKeywordStuffing: 'Veuillez attendez pour le  keyword Stuffing analyse',
};

export const CLIENT_HISTORY_TABLE = [
  { label: 'Titre du texte', field: 'textTitle', sortable: true, sort: 'asc' },

  { label: "Date d'ajout", field: 'createdAt', sortable: true, sort: 'asc' },
  {
    label: 'Édité par le client',
    field: 'edited',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Révision demandée',
    field: 'revisionSent',
    sortable: true,
    sort: 'asc',
  },
  { label: 'Validé', field: 'validated', sortable: true, sort: 'asc' },
  {
    label: 'Date de validation',
    field: 'validateAt',
    sortable: true,
    sort: 'asc',
  },
  {
    label: '',
    field: 'downloadAll',
    sortable: false,
  },

  {
    label: '',
    field: 'deployAll',
    sortable: false,
  },
  {
    label: '',
    field: 'selectAll',
    sortable: false,
  },
];

export const HISTORY_TABLE = [
  { label: 'Titre du texte', field: 'textTitle', sortable: true, sort: 'asc' },

  { label: "Date d'ajout", field: 'createdAt', sortable: true, sort: 'asc' },
  {
    label: 'Édité par le client',
    field: 'edited',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Révision demandée',
    field: 'revisionSent',
    sortable: true,
    sort: 'asc',
  },
  { label: 'Validé', field: 'validated', sortable: true, sort: 'asc' },
  {
    label: 'Date de validation',
    field: 'validateAt',
    sortable: true,
    sort: 'asc',
  },
  {
    label: '',
    field: 'textDeployed',
    sortable: false,
  },
  {
    label: '',
    field: 'downloadAll',
    sortable: false,
  },
];

export const EDITORS_LIST = [
  'benedicte.auber@pixalione.com',
  'emilie.chaput@pixalione.com',
  'josephine.dacosta@pixalione.com',
  'yasmine.kassar@pixalione.com',
  'melyne.mathieu@pixalione.com',
  'vicky.guiragossian@pixalione.com',
];

export const entities = {
  '&amp;': '&',
  '&quot;': '"',
  '&#39;': "'",
  '&lt;': '&lt;', // Preserve < as &lt;
  '&gt;': '&gt;', // Preserve > as &gt;
  '&nbsp;': ' ',
  '&iexcl;': '¡',
  '&cent;': '¢',
  '&pound;': '£',
  '&curren;': '¤',
  '&yen;': '¥',
  '&brvbar;': '¦',
  '&sect;': '§',
  '&uml;': '¨',
  '&copy;': '©',
  '&ordf;': 'ª',
  '&laquo;': '«',
  '&not;': '¬',
  '&shy;': '­',
  '&reg;': '®',
  '&macr;': '¯',
  '&deg;': '°',
  '&plusmn;': '±',
  '&sup2;': '²',
  '&sup3;': '³',
  '&acute;': '´',
  '&micro;': 'µ',
  '&para;': '¶',
  '&middot;': '·',
  '&cedil;': '¸',
  '&sup1;': '¹',
  '&ordm;': 'º',
  '&raquo;': '»',
  '&frac14;': '¼',
  '&frac12;': '½',
  '&frac34;': '¾',
  '&iquest;': '¿',
  '&Agrave;': 'À',
  '&Aacute;': 'Á',
  '&Acirc;': 'Â',
  '&Atilde;': 'Ã',
  '&Auml;': 'Ä',
  '&Aring;': 'Å',
  '&AElig;': 'Æ',
  '&Ccedil;': 'Ç',
  '&Egrave;': 'È',
  '&Eacute;': 'É',
  '&Ecirc;': 'Ê',
  '&Euml;': 'Ë',
  '&Igrave;': 'Ì',
  '&Iacute;': 'Í',
  '&Icirc;': 'Î',
  '&Iuml;': 'Ï',
  '&ETH;': 'Ð',
  '&Ntilde;': 'Ñ',
  '&Ograve;': 'Ò',
  '&Oacute;': 'Ó',
  '&Ocirc;': 'Ô',
  '&Otilde;': 'Õ',
  '&Ouml;': 'Ö',
  '&times;': '×',
  '&Oslash;': 'Ø',
  '&Ugrave;': 'Ù',
  '&Uacute;': 'Ú',
  '&Ucirc;': 'Û',
  '&Uuml;': 'Ü',
  '&Yacute;': 'Ý',
  '&THORN;': 'Þ',
  '&szlig;': 'ß',
  '&agrave;': 'à',
  '&aacute;': 'á',
  '&acirc;': 'â',
  '&atilde;': 'ã',
  '&auml;': 'ä',
  '&aring;': 'å',
  '&aelig;': 'æ',
  '&ccedil;': 'ç',
  '&egrave;': 'è',
  '&eacute;': 'é',
  '&ecirc;': 'ê',
  '&euml;': 'ë',
  '&igrave;': 'ì',
  '&iacute;': 'í',
  '&icirc;': 'î',
  '&iuml;': 'ï',
  '&eth;': 'ð',
  '&ntilde;': 'ñ',
  '&ograve;': 'ò',
  '&oacute;': 'ó',
  '&ocirc;': 'ô',
  '&otilde;': 'õ',
  '&ouml;': 'ö',
  '&divide;': '÷',
  '&oslash;': 'ø',
  '&ugrave;': 'ù',
  '&uacute;': 'ú',
  '&ucirc;': 'û',
  '&uuml;': 'ü',
  '&yacute;': 'ý',
  '&thorn;': 'þ',
  '&yuml;': 'ÿ',
  '&OElig;': 'Œ',
  '&oelig;': 'œ',
  '&Scaron;': 'Š',
  '&scaron;': 'š',
  '&Yuml;': 'Ÿ',
  '&fnof;': 'ƒ',
  '&circ;': 'ˆ',
  '&tilde;': '˜',
  '&Alpha;': 'Α',
  '&Beta;': 'Β',
  '&Gamma;': 'Γ',
  '&Delta;': 'Δ',
  '&Epsilon;': 'Ε',
  '&Zeta;': 'Ζ',
  '&Eta;': 'Η',
  '&Theta;': 'Θ',
  '&Iota;': 'Ι',
  '&Kappa;': 'Κ',
  '&Lambda;': 'Λ',
  '&Mu;': 'Μ',
  '&Nu;': 'Ν',
  '&Xi;': 'Ξ',
  '&Omicron;': 'Ο',
  '&Pi;': 'Π',
  '&Rho;': 'Ρ',
  '&Sigma;': 'Σ',
  '&Tau;': 'Τ',
  '&Upsilon;': 'Υ',
  '&Phi;': 'Φ',
  '&Chi;': 'Χ',
  '&Psi;': 'Ψ',
  '&Omega;': 'Ω',
  '&alpha;': 'α',
  '&beta;': 'β',
  '&gamma;': 'γ',
  '&delta;': 'δ',
  '&epsilon;': 'ε',
  '&zeta;': 'ζ',
  '&eta;': 'η',
  '&theta;': 'θ',
  '&iota;': 'ι',
  '&kappa;': 'κ',
  '&lambda;': 'λ',
  '&mu;': 'μ',
  '&nu;': 'ν',
  '&xi;': 'ξ',
  '&omicron;': 'ο',
  '&pi;': 'π',
  '&rho;': 'ρ',
  '&sigmaf;': 'ς',
  '&sigma;': 'σ',
  '&tau;': 'τ',
  '&upsilon;': 'υ',
  '&phi;': 'φ',
  '&chi;': 'χ',
  '&psi;': 'ψ',
  '&omega;': 'ω',
  '&thetasym;': 'ϑ',
  '&upsih;': 'ϒ',
  '&piv;': 'ϖ',
  '&ensp;': ' ',
  '&emsp;': ' ',
  '&thinsp;': ' ',
  '&zwnj;': '‌',
  '&zwj;': '‍',
  '&lrm;': '‎',
  '&rlm;': '‏',
  '&ndash;': '–',
  '&mdash;': '—',
  '&lsquo;': '‘',
  '&rsquo;': '’',
  '&sbquo;': '‚',
  '&ldquo;': '“',
  '&rdquo;': '”',
  '&bdquo;': '„',
  '&dagger;': '†',
  '&Dagger;': '‡',
  '&bull;': '•',
  '&hellip;': '…',
  '&permil;': '‰',
  '&prime;': '′',
  '&Prime;': '″',
  '&lsaquo;': '‹',
  '&rsaquo;': '›',
  '&oline;': '‾',
  '&frasl;': '⁄',
  '&euro;': '€',
  '&image;': 'ℑ',
  '&weierp;': '℘',
  '&real;': 'ℜ',
  '&trade;': '™',
  '&alefsym;': 'ℵ',
  '&larr;': '←',
  '&uarr;': '↑',
  '&rarr;': '→',
  '&darr;': '↓',
  '&harr;': '↔',
  '&crarr;': '↵',
  '&lArr;': '⇐',
  '&uArr;': '⇑',
  '&rArr;': '⇒',
  '&dArr;': '⇓',
  '&hArr;': '⇔',
  '&forall;': '∀',
  '&part;': '∂',
  '&exist;': '∃',
  '&empty;': '∅',
  '&nabla;': '∇',
  '&isin;': '∈',
  '&notin;': '∉',
  '&ni;': '∋',
  '&prod;': '∏',
  '&sum;': '∑',
  '&minus;': '−',
  '&lowast;': '∗',
  '&radic;': '√',
  '&prop;': '∝',
  '&infin;': '∞',
  '&ang;': '∠',
  '&and;': '∧',
  '&or;': '∨',
  '&cap;': '∩',
  '&cup;': '∪',
  '&int;': '∫',
  '&there4;': '∴',
  '&sim;': '∼',
  '&cong;': '≅',
  '&asymp;': '≈',
  '&ne;': '≠',
  '&equiv;': '≡',
  '&le;': '≤',
  '&ge;': '≥',
};

export const GOVERNED_WRITERS = [
  'Arne.Leen@rajapack.be',
  'jpedros@diormail.com',
  'mpenven@diormail.com',
];

export const GOVERNED_WRITERS_WITH_VALIDATION = [
  'Arne.Leen@rajapack.be',
  'jpedros@diormail.com',
  'mpenven@diormail.com',
];
