import React from 'react';

export default function CheckBox({ name, tick, onCheck }) {
  return (
    <div
      className={`box-checkbox ${
        name !== 'select-all'
          ? 'd-flex justify-content-center align-items-center'
          : ''
      }`}
    >
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        checked={tick}
        onChange={onCheck}
        disabled={name === 'gouvernance-gray' ? true : false}
      />
    </div>
  );
}
