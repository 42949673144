import axios from 'axios';

export const keywordsAutoGeneration = async (
  orderId,
  keyword,
  orderSiloName,
  url,
  axiosPrivate,
  axiosMonetoring,
  transformData,
  formatKeywordData,
  extractKeywordsWithFrequency,
  setKeywords,
  setCombinedKeywords,
  setCombinedKeywordsList,
  setWebsites,
  setErrorSuccessMsg,
  setIsSuccess,
  setErrorSuccess,
  t,
  setLoading
) => {
  setLoading(true);

  const textKeyword = keyword;

  try {
    const res = await axiosPrivate.get('/website/getAccountAndUrl', {
      params: { commandId: orderId },
    });

    let account = res.data.account.trim();
    const websiteUrl = res.data.urlSite.trim();

    try {
      const externalRes = await axios.get(
        `https://tools.pixalione.fr:8686/clientApi/getAccountByWebsite?website=${websiteUrl}`
      );
      if (externalRes.data && typeof externalRes.data === 'string') {
        account = externalRes.data.trim();
      }
    } catch (err) {
      console.log('"Failed to fetch external account name, using default."');
    }

    const headkeys =
      orderSiloName && orderSiloName !== textKeyword
        ? textKeyword.includes(',')
          ? textKeyword
              .split(',')
              .map((key) => `&headkeys=${encodeURIComponent(key.trim())}`)
              .join('&')
          : `&headkeys=${encodeURIComponent(textKeyword.trim())}`
        : '';

    const keyword = encodeURIComponent(
      orderSiloName ? orderSiloName.trim() : textKeyword.trim()
    );
    const urlParam = url ? `&url=${encodeURIComponent(url.trim())}` : '';
    const webUrl = encodeURIComponent(res.data.urlSite.trim());

    // const account = res.data.account.trim();

    // const headkeys =
    //   orderSiloName && orderSiloName != textKeyword
    //     ? textKeyword.includes(',')
    //       ? textKeyword
    //           .split(',')
    //           .map((key) => `&headkeys=${key.trim()}`)
    //           .join('&')
    //       : `&headkeys=${textKeyword}`
    //     : '';
    // const keyword = orderSiloName ? orderSiloName.trim() : textKeyword.trim();
    // const urlParam = url ? `&url=${url.trim()}` : '';
    // const webUrl = res.data.urlSite.trim();

    const constructedUrl = `/get_all_upper_keywords_for_typix?account=${encodeURIComponent(
      account
    )}${headkeys}&keyword=${keyword}${urlParam}&webUrl=${webUrl}`;

    const monitoringRes = await axiosMonetoring.get(constructedUrl);

    const missingLinks = monitoringRes.data['missing_links'].join('\n');

    const combinedKeywordsList = transformData(monitoringRes.data);

    const combinedKeywords = formatKeywordData(combinedKeywordsList);

    const keywordsWithFreq = extractKeywordsWithFrequency(combinedKeywordsList);

    setKeywords(keywordsWithFreq);
    setCombinedKeywords(combinedKeywords);
    setCombinedKeywordsList(combinedKeywordsList);
    setWebsites(missingLinks);
  } catch (e) {
    if (e.response) {
      switch (e.response.status) {
        case 409:
          setErrorSuccessMsg(
            t(
              'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com'
            )
          );
          break;
        case 404:
          setErrorSuccessMsg(
            t(
              'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com'
            )
          );
          break;
        default:
          setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
          break;
      }
    }
    setIsSuccess(false);
    setErrorSuccess(true);
  } finally {
    setLoading(false);
  }
};
