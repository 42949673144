import he from 'he';
import { diffWords } from 'diff';

import axiosOfflineComparison from 'config/axiosOfflineComparison';
import axiosPrivate from 'config/axiosPrivate';

import { setKeyWordList } from 'helpers/PdfFunction';
import {
  removeWhitespace,
  replaceTextInBold,
  entitiesToSpecialChars,
  stripHTML,
} from 'utils/StringUtils';

import { ROLES } from 'utils/Constants';

export const fetchAnalysisResult = (
  text,
  initialText,
  content,
  keywords,
  languageId,
  orderType,
  user,
  dispatch,
  setLoading,
  setResult,
  setContent,
  setList,
  setIsSuccess,
  setErrorSuccess,
  setErrorSuccessMsg,
  updateUserAnalysisCount,
  t,
  isAutomaticTextAnalysis
) => {
  const list = setKeyWordList(keywords);

  let textInput = '';

  if (list === null) {
    setErrorSuccessMsg(
      t(
        `Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.`
      )
    );
    setIsSuccess(false);
    setErrorSuccess(true);
    setLoading(false);
    setResult(null);
    return;
  }

  try {
    if (initialText && text) {
      let newContent = removeWhitespace(text);
      let oldContent = removeWhitespace(initialText);

      const diff = diffWords(oldContent, newContent);

      const addedWords = diff
        .filter((part) => part.added)
        .map((part) => part.value)
        .join(' ');

      textInput = addedWords;
    } else {
      textInput = text;
    }
  } catch (e) {
    textInput = text;
    console.log('Error in text processing', e);
  }
  const textContent = stripHTML(he.decode(textInput.trim().normalize('NFC')));

  axiosOfflineComparison
    .post(
      '/offline-comparison/analyzeKeywordsWithFreqJSON',
      { content: textContent, keywords: list },
      { params: { language: languageId } }
    )
    .then((res) => {
      if (res.status === 200 && res.data.status === 'ok') {
        setLoading(false);
        setList(list);
        setResult(res.data);

        let processedContent = '';
        if (orderType === 'PREMIUM') {
          if (/<\/?[a-z][\s\S]*>/i.test(text)) {
            processedContent = entitiesToSpecialChars(content);
          } else {
            processedContent = he.decode(content);
          }
        } else {
          processedContent = he.decode(content);
        }

        let filteredKeywords = res.data.wordsInBold.filter((keyword) => {
          return keyword.length > 0 && keyword !== '';
        });

        const highlightedContent = replaceTextInBold(
          processedContent,
          filteredKeywords
        );

        if (!isAutomaticTextAnalysis) {
          setContent(highlightedContent);
          if (user && user.role === ROLES.WRITER) {
            axiosPrivate
              .put('/user/increaseAnalysis')
              .then((res) => {
                dispatch(updateUserAnalysisCount(res.data));
              })
              .catch((e) => console.log('text analysis error', e));
          }
        }
      } else {
        setLoading(false);
        setResult(null);
      }
    })

    .catch((e) => {
      if (e?.response?.status) {
        setErrorSuccessMsg(
          t(
            `Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.`
          )
        );
        setIsSuccess(false);
        setErrorSuccess(true);
      }
    });
};
