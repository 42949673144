import axiosFacturation from "config/axiosFacturation";
import React, { useState,  } from 'react';

const FileDownloadButton = () => {
  const [generateExcelLoading, setGenerateExcelLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setGenerateExcelLoading(true)
      // Make a GET request to your endpoint
      const response = await axiosFacturation.post("/billing/bills/all/download",{}, {
        responseType: "blob", // Important for handling binary data
      });

      // Create a temporary URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Set the file name (can also be extracted from headers if provided)
      link.setAttribute("download", "bills-extracts.xlsx");
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
      setGenerateExcelLoading(false);
    } catch (error) {
      console.error("Error downloading the file:", error);
      setGenerateExcelLoading(false);
    }
  };

  return (
    <span style={{display:"flex", marginRight:"15px"}}>
        <div className="excel-export-button" button onClick={handleDownload}>
        </div>
        <h2
        className={
            generateExcelLoading ? 'bt-reload-dynamic' : ''
        }
    >
        {' '}
    </h2>
  </span>
  );
};

export default FileDownloadButton;
