import React, { useState, useEffect } from 'react';

const SavingIndicator = ({ isSaving }) => {
  return (
    <div className="auto-save-display">
      {isSaving && (
        <>
          <div className="loader" />
          <span style={{ marginLeft: '8px' }}>Saving...</span>
        </>
      )}
      {!isSaving && <span>✔ Saved!</span>}
    </div>
  );
};

export default SavingIndicator;
