import { useState, useEffect, useRef } from 'react';

export function useAutosave(saveFunction, delay = 1000) {
  const [data, setData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const timeoutId = useRef(null);
  const queue = useRef([]); // Stores save requests in a queue
  const isProcessing = useRef(false); // Tracks if we're currently processing a save request

  // Cleanup effect: Clears any pending timeout when component unmounts
  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  // Function that processes the queue
  const processQueue = async () => {
    if (isProcessing.current || queue.current.length === 0) return;

    isProcessing.current = true;
    const nextData = queue.current.shift();

    try {
      setIsSaving(true);
      await saveFunction(...nextData);
    } catch (error) {
      console.error('Autosave failed:', error);
    } finally {
      setIsSaving(false);
      isProcessing.current = false;
      if (queue.current.length > 0) {
        processQueue();
      }
    }
  };

  // Function to schedule a save
  const scheduleSave = (...args) => {
    setData(args);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      queue.current.push(args);
      processQueue();
    }, delay);
  };

  return { data, scheduleSave, isSaving };
}
