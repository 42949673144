import React from 'react';
import TextCheckBox from 'components/shared/TextCheckBox';

const TextCheckboxTableRow = ({
  text,
  order,
  selectedTextsForRevisionOrValidation,
  deploymentQueue,
  setDeploymentQueue,
  selectedTextsForValidation,
  setSelectedTextsForRevisionOrValidation,
  setSelectedTextsForValidation,
  isEligibleForRevision,
  isAwaitingDeployment,
  isEligibleForValidation,
  isTextEligibleForValidationAndRevision,
}) => {
  return (
    <td>
      <TextCheckBox
        orderType={order.type}
        text={text}
        selectionHandlers={{
          selectedTextsForRevisionOrValidation,
          setSelectedTextsForRevisionOrValidation,
          selectedTextsForValidation,
          setSelectedTextsForValidation,
          deploymentQueue,
          setDeploymentQueue,
        }}
        eligibility={{
          isAwaitingDeployment: isAwaitingDeployment,
          isEligibleForRevision: isEligibleForRevision,
          isEligibleForValidation: isEligibleForValidation,
          isTextEligibleForValidationAndRevision:
            isTextEligibleForValidationAndRevision,
        }}
      />
    </td>
  );
};

export default TextCheckboxTableRow;
