import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from 'store/UserSession';
import { clientOrderDisplaySelector } from 'store/Client/OrderDisplay';
import OrderDetails from 'components/shared/OrderDetails';
import OrderHistory from 'components/shared/OrderHistory';
import { OrderTextsDetailsList } from 'components/shared/OrderDetails/OrderTextsDetailsList';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';
import CustomSearchBox from 'components/shared/SearchBar/CustomSearchBox';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { pageNbOptions } from 'utils/Constants';
import { ROLES } from 'utils/Constants';

import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const navItems = [
  { text: 'Contenus de la commande', active: 1 },

  { text: 'Détail de la commande', active: 2 },
];

function ClientOrderDisplay() {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useSelector(UserSessionSelector);

  const { order, textList, isLoading, isError } = useSelector(
    clientOrderDisplaySelector
  );
  const [title, setTitle] = useState('');

  const [searchValue, setSearchValue] = useHistoryState('searchValue', '');
  const [currentPage] = useState(1);

  const [active, setActive] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);

  useEffect(() => {
    document.title = t('Typix - Détails de la commande');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setTitle(order.title);
    }
  }, [isError, isLoading, order]);

  if (isLoading || isError) {
    return <Loader />;
  }
  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{title}</h1>
      </div>

      <div className="row">
        <div className="col-12 col-md-7">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => setActive(item.active)}
                  className={`nav-link ${
                    item.active === active ? 'active' : ''
                  }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {t(item.text)}
                </button>
              </li>
            ))}
          </ul>
          {active === 2 && (
            <OrderDetails
              searchValue={searchValue}
              currentPage={currentPage}
              textsPerPage={elementsPerPage}
            />
          )}
        </div>
        {active === 2 ? (
          <div className="col-12  col-md-5">
            <div className="box-text-title">
              <div className="box-ch">
                <span>{t('Contenu disponible')}</span>
                <CustomTooltip
                  title={
                    <span>
                      <p>
                        {t('Noir')} :{' '}
                        {t('contenu en attente de vérification client')}
                      </p>
                      <p>
                        {t('Orange')} :{' '}
                        {t('contenu en attente de repasse rédacteur')}
                      </p>
                      <p>
                        {t('Violet')} :{' '}
                        {t('contenu repassé, en attente validation client')}
                      </p>
                      <p>
                        {t('Vert')} : {t('contenu validé par le client')}
                      </p>
                    </span>
                  }
                  placement="top"
                >
                  <button type="button" className="btn-circle"></button>
                </CustomTooltip>
              </div>
              {textList.length > 0 && (
                <div className="justify-content-end">
                  <ExcelExportButton
                    orderTitle={order.title}
                    orderId={order.id}
                    orderLanguage={order.language}
                  />
                </div>
              )}
            </div>
            <OrderTextsDetailsList
              orderTextAmount={order.textAmount}
              currentPage={currentPage}
              textsPerPage={elementsPerPage}
            />
          </div>
        ) : (
          ''
          // user.role !== ROLES.CLIENT && (
          //   <CustomSearchBox
          //     searchValue={searchValue}
          //     setSearchValue={setSearchValue}
          //     elementsPerPage={elementsPerPage}
          //     setElementsPerPage={setElementsPerPage}
          //     pageNbOptions={pageNbOptions}
          //     t={t}
          //   />
          // )
        )}
      </div>

      {active === 1 && (
        <OrderHistory
          searchValue={searchValue}
          textsPerPage={elementsPerPage}
          orderTextAmount={order.textAmount}
        />
      )}
    </div>
  );
}

export default ClientOrderDisplay;
