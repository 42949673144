import styles from './Login.module.css';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'config/axios';
import TokenService from 'config/token.service';
import { useDispatch } from 'react-redux';
import { updateFlag } from 'store/UserSession';
import { fetchUser } from 'store/UserSession/features';

import { Alert, AlertTitle, CircularProgress, IconButton } from '@mui/material';
import ForgotPasswordModal from 'components/shared/modal/ForgotPasswordModal';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/user/login', { mail: email, password: password })
      .then((response) => {
        TokenService.setUser(response.data);
        dispatch(fetchUser(response.data));
        dispatch(updateFlag(true));
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            setErrorMessage(t('Veuillez saisir des champs valides'));
            break;
          case 404:
            setErrorMessage(`${t("L'émail")} ${email} ${t("n'existe pas")}`);

            break;
          case 401:
            setErrorMessage(t('Mot de passe incorrect'));
            break;
          default:
            setErrorMessage(t("Une erreur s'est produite, veuillez réessayer"));
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.LoginformContainer}>
        <div>
          <img
            src="/Images/logo.png"
            alt="Logo Typix"
            className={styles.formLogo}
          />

          <h1 className={styles.formTitle}>Bienvenue !</h1>
        </div>

        <p className={styles.formSubTitle}>
          Accédez à votre compte pour gérer vos contenus.
        </p>

        <form className={styles.loginForm} onSubmit={handleSubmit}>
          <div className={styles.inputContainer}>
            <label htmlFor="email">{t('Email')}</label>

            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Veuillez saisir votre adresse mail"
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="password">Mot de passe</label>

            <div className={styles.passwordWrapper}>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Entrez votre mot de passe"
              ></input>

              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                className={styles.togglePassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>

          {errorMessage && (
            <Alert className={styles.alertError} severity="error">
              <AlertTitle>{t('Erreur')}</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          <button
            disabled={loading}
            className={styles.loginButton}
            type="submit"
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              'Se connecter'
            )}
          </button>

          <a
            onClick={(e) => {
              e.preventDefault();
              setForgotPassword(true);
            }}
            href="#"
            style={{
              display: 'block',
            }}
          >
            {t('mot de passe oublié')} ?
          </a>
        </form>

        <div className={styles.separator}>Vous n'avez pas de compte ? </div>

        <span>
          Veuillez contacter notre support au{' '}
          <a href="mailto:support-typix@pixalione.com">
            support-typix@pixalione.com
          </a>
        </span>
      </div>

      <img
        src="/Images/login-illustration.jpg"
        alt="Illustration"
        className={styles.sideImage}
      />

      <ForgotPasswordModal
        open={forgotPassword}
        handleClose={() => setForgotPassword(false)}
      />
    </div>
  );
}

export default Login;
