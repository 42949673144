import axiosPrivate from 'config/axiosPrivate';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { fetchAdminOrderLineDisplay } from 'store/Admin/OrderDisplay/features';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';
import { deliverTextsOfTextList } from 'store/Admin/OrderDisplay';
import { clientValidateTextsOfTextList } from 'store/Client/OrderDisplay';

import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from 'store/Client/OrderDisplay/features';
import { filterTextByStatus } from 'utils/Constants';

export const GlobalTextsValidation = async (
  selectedTexts,
  setSelectedTexts,
  orderId,
  orderTextAmount,
  setConfirmation,
  setIsSuccess,
  setErrorSuccessMsg,
  setErrorSuccess,
  t,
  dispatch
) => {
  try {
    const isAllTextsSelected = selectedTexts.length === orderTextAmount;

    if (isAllTextsSelected) {
      await axiosPrivate.put(`/commands/${orderId}/validateAllTexts`);
    } else {
      await axiosPrivate.post('/texts/multi-validate', selectedTexts);
    }
    setConfirmation(false);

    dispatch(
      clientValidateTextsOfTextList({
        selectedTexts,
        key: 'validated',
        value: true,
      })
    );

    setIsSuccess(true);
    setErrorSuccessMsg(
      t(
        selectedTexts.length > 1
          ? 'Les textes ont été validés avec succès.'
          : 'Le texte a été validé avec succès.'
      )
    );
    setErrorSuccess(true);
  } catch (error) {
    console.error('An error occurred during text validation:', error.message);
  }
};

export const GlobalTextsDlivery = async (
  selectedTextsForDelivery,
  setSelectedTextsForDelivery,
  setSelectedTextsToReDraft,
  orderId,
  orderTextAmount,
  websiteId,
  setSelectedStatus,
  setConfirmation,
  setIsSuccess,
  setErrorSuccessMsg,
  setErrorSuccess,
  t,
  dispatch
) => {
  try {
    const isAllTextsSelected =
      selectedTextsForDelivery.length === orderTextAmount;

    if (isAllTextsSelected) {
      await axiosPrivate.put(`/commands/${orderId}/deliverAllTexts`);
    } else {
      await axiosPrivate.post('/texts/admin-deliver', selectedTextsForDelivery);
    }

    setConfirmation(false);

    dispatch(
      deliverTextsOfTextList({
        selectedTextsForDelivery,
        key: 'delivered',
        value: true,
      })
    );
    setSelectedTextsForDelivery([]);
    setSelectedTextsToReDraft([]);

    setSelectedStatus(filterTextByStatus[0]);

    dispatch(fetchAllOrders());
    dispatch(fetchWebsiteOrdersList(websiteId));
    dispatch(fetchAdminOrderLineDisplay(orderId));
    setIsSuccess(true);
    setErrorSuccessMsg(
      t(
        selectedTextsForDelivery.length > 1
          ? 'Les textes ont été livrés avec succès.'
          : 'Le texte a été livré avec succès.'
      )
    );
    setErrorSuccess(true);
  } catch (error) {
    console.error('An error occurred during text validation:', error.message);
  }
};
