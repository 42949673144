import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchAnalysisResult } from 'helpers/fetchAnalysisResultHelper';

import {
  updateUserAnalysisCount,
  UserSessionSelector,
} from 'store/UserSession';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

export const AnalyseText = ({
  orderType,
  keywords,
  initialText,
  text,
  content,
  setContent,
  analysis,
  limit,
  language,
  setList,
  setResult,
  setLoading,
  setIsAutomaticTextAnalysis = () => {},
  isAutomaticTextAnalysis = false,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  if (
    keywords === '' ||
    content === '' ||
    (analysis && limit ? analysis === limit : false)
  )
    return <button className="bt-submit-disabled-y"> {t('Analyser')} </button>;

  return (
    <div>
      <button
        className="bt-submit-y"
        onClick={() => {
          setResult(null);
          setLoading(true);
          setIsAutomaticTextAnalysis(false);

          setTimeout(() => {
            fetchAnalysisResult(
              text,
              initialText,
              content,
              keywords,
              language.id,
              orderType,
              user,
              dispatch,
              setLoading,
              setResult,
              setContent,
              setList,
              setIsSuccess,
              setErrorSuccess,
              setErrorSuccessMsg,
              updateUserAnalysisCount,
              t,
              false
            );
          }, 0);
        }}
      >
        {t('Analyser')}
      </button>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};

export default AnalyseText;
