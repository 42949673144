import React from 'react';
import { replaceAmpersands } from 'utils/StringUtils';
import { extractAnchorTexts } from 'helpers/FormatKeywordsHelper';

const InternalLinksList = ({ websites, actualHtmlContent, t }) => {
  const isWordInHtmlContent = (word) => {
    const suffixes = ['/ ', ' ', '?', '"', '>', '<', '/?', '/"', '/>', '/<'];
    return suffixes.some((suffix) => actualHtmlContent.includes(word + suffix));
  };

  const isWordInAnchorTag = (word) => {
    const anchorTexts = extractAnchorTexts(actualHtmlContent);
    return anchorTexts.some((text) =>
      text.toLowerCase().includes(word.toLowerCase())
    );
  };

  const formatMarkColor = (sanitizedWord) => {
    const markColor =
      isWordInAnchorTag(sanitizedWord) || isWordInHtmlContent(sanitizedWord)
        ? { color: 'green', backgroundColor: '#2cce6e' }
        : { color: 'red', backgroundColor: '#ffc2c2' };

    return markColor;
  };

  return (
    <div className="scroll">
      <div className="header-title mb-0">
        <>{t('Maillage Interne')}</>
      </div>
      {(websites?.length === 0 || !websites?.length) && (
        <div>
          <>{t('Inserez le maillage interne')}</>
        </div>
      )}
      <div>
        {websites &&
          websites.split('\n').map((word, index) => {
            const cleanWord = word.replace(/^new page-/, '').trim();
            const sanitizedWord = replaceAmpersands(cleanWord);
            const markColor = formatMarkColor(sanitizedWord);

            return (
              <div key={index}>
                <mark style={markColor}>{cleanWord}</mark>
                <br />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InternalLinksList;
