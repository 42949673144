import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { UserSessionSelector } from './store/UserSession';

import Login from './pages/shared/Login';
import ResetPassword from './pages/shared/ResetPassword.js';

import AdminMainMenu from './components/Admin/AdminMainMenu';
import WriterMainMenu from './components/Writer/WriterMainMenu';
import ConsultantMainMenu from './components/Consultant/ConsultantMainMenu';
import ClientMainMenu from './components/Client/ClientMainMenu';

import { ROLES } from './utils/Constants';

import './App.css';

export default function App() {
  let { user } = useSelector(UserSessionSelector);

  if (user && user.role === ROLES.ADMIN)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Admin/MainMenu'} />;
          }}
        />
        <Route path="/Admin" component={AdminMainMenu} />
        <Redirect to="/Admin/MainMenu" />
      </Switch>
    );

  if (user && user.role === ROLES.WRITER)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Redacteur/MainMenu'} />;
          }}
        />
        <Route path="/Redacteur" component={WriterMainMenu} />
        <Redirect to="/Redacteur/MainMenu" />
      </Switch>
    );

  if (user && user.role === 'CONSULTANT')
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Consultant/MainMenu'} />;
          }}
        />
        <Route path="/Consultant" component={ConsultantMainMenu} />
        <Redirect to="/Consultant/MainMenu" />
      </Switch>
    );

  if (user && user.role === 'CLIENT')
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Client/MainMenu'} />;
          }}
        />
        <Route path="/Client" component={ClientMainMenu} />
        <Redirect to="/Client/MainMenu" />
      </Switch>
    );

  return (
    <div>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
}
