import axios from 'axios';
import { axiosMonetoringAPI } from './endpoints';

const BASE_URL = axiosMonetoringAPI;

const axiosMonetoring = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Gateway-Auth':
      'Bearer eyJ4NXQiOiJPREJtTVRVMFpqSmpPREprTkdZMVpUaG1ZamsyWVRZek56UmpZekl6TVRCbFlqRTBNV0prWTJJeE5qZzNPRGRqWVdRNVpXWmhOV0kwTkRBM1pqTTROUSIsImtpZCI6Ik9EQm1NVFUwWmpKak9ESmtOR1kxWlRobVlqazJZVFl6TnpSall6SXpNVEJsWWpFME1XSmtZMkl4TmpnM09EZGpZV1E1WldaaE5XSTBOREEzWmpNNE5RX1JTMjU2IiwidHlwIjoiYXQrand0IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJlNDlmNDEzMS1mMWRhLTQ2MjItYjIwMS03ZmI3MjVkNGI3YjUiLCJhdXQiOiJBUFBMSUNBVElPTiIsImF1ZCI6ImxmYmRxSXNHT1dmaE1VNzZXd21fWGZRZDBVSWEiLCJuYmYiOjE3MzgxNDg4MjcsImF6cCI6ImxmYmRxSXNHT1dmaE1VNzZXd21fWGZRZDBVSWEiLCJzY29wZSI6ImRlZmF1bHQiLCJpc3MiOiJodHRwczovL2FwaS1tYW5hZ2VyLWJnLm1vbmV0b3JpbmcuY29tL29hdXRoMi90b2tlbiIsImV4cCI6Mzg4NTYzMjQ3NCwiaWF0IjoxNzM4MTQ4ODI3LCJqdGkiOiIzOWIwMzJjYS1jOGViLTRmMTUtOWE4MS1mZTUxNDc1NTcxYWQiLCJjbGllbnRfaWQiOiJsZmJkcUlzR09XZmhNVTc2V3dtX1hmUWQwVUlhIn0.ieMEHtg0mNo4_rPX7Y8aNIcSzLAQ0dFivvswcFkgGeREE1SAtmvBdHX0m_6Lr2VubNwVfJztOqArd_5P_ENjT_v76ioenqZbnNcgzo9W3oEi_Z-PsMG2EZsz7pmsVhHcPo63zN--XTwtaB4W-gfPoAFjgD16xPb0t8OW6jCyw9bNMOxPJchxYItnc7TPdaaf7VjAgf31r4ZqfZKFpG8jl0bkkA9UcbjX28wLk2x65H7rK_VluAoezCixhseoJnBrq-vJZmnf7pxuycpMGRfUkn8_efyfAQ5OTGR3iEwkN0k-G5zLMb-kmc0yTfkKYAIf3apOuW1edDYVlVe2-u2b2w',
    'Content-Type': 'application/json',
  },
});

export default axiosMonetoring;
