import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GOVERNED_WRITERS } from 'utils/Constants';
import axiosPrivate from 'config/axiosPrivate';

import { addOrder } from 'store/Admin/OrderList';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { disabledInput } from 'components/shared/disabledClasses';
import CheckBox from 'components/shared/CheckBox';
import CheckBoxGroup from 'components/shared/CheckboxGroup';

import { ROLES, ORDERSTATUS, ORDER_CONTENT_TYPE } from 'utils/Constants';
import endpoints from 'config/endpoints.json';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './order.css';
import { useTranslation } from 'react-i18next';
import { animationUrl } from 'config/endpoints';
import { EDITORS_LIST } from 'utils/Constants';

const API = {
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: '/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  createOrder: '/commands',
  createSmartOrder: '/commands/addSmartCommand',
};
const editorsList = EDITORS_LIST;

export default function CreateOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);
  const [writersList, setWritersList] = useState([]);

  const [account, setAccount] = useState(null);
  const [textAmount, setTextAmount] = useState(0);
  const [maxTextAmount, setMaxTextAmount] = useState(0);
  const [orderThematics, setOrderThematics] = useState([]);
  const [orderLanguage, setOrderLanguage] = useState(null);
  const [accountsList, setAccountsList] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [devis, setDevis] = useState(null);
  const [devisList, setDevisList] = useState([]);
  const [task, setTask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [titled, setTitled] = useState('');
  const [websiteList, setWebsiteList] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [editor, setEditor] = useState('');
  const [contentType, setContentType] = useState(null);
  const [orderType, setOrderType] = useState('');
  const [maxWords, setMaxWords] = useState(0);
  const [minWords, setMinWords] = useState(0);

  const [actualBilling, setActualBilling] = useState(false);
  const [wordsManagedNumber, setWordsManagedNumber] = useState(0);
  const [siloName, setSiloName] = useState('');

  const [detailedBrief, setDetailedBrief] = useState('');
  const [semanticGapFocus, setSemanticGapFocus] = useState(false);

  const [clientDocumentation, setClientDocumentation] = useState('');

  const [semanticGap, setSemanticGap] = useState('');

  const [assignedWriters, setAssignedWriters] = useState([
    {
      writer: null,
      price: 0,
      deadline: null,
      textAmount: 0,
      minWords: 0,
      maxWords: 0,
      actualBilling: false,
      wordsManagedNumber: 0,
    },
  ]);

  const [maxNumber, setMaxNumber] = useState(0);
  const [disabledForm, setDisabledForm] = useState(true);

  //set order type
  const [checkboxStates, setCheckboxStates] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [showElement, setShowElement] = useState(true);
  const [isTranslation, setIsTranslation] = useState(false);
  const [isRedaction, setIsRedaction] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isContentTypeActive, setIsContentTypeActive] = useState(true);
  const [isGovernance, setIsGovernance] = useState(false);

  const isDemo = endpoints.isDemo;

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('Typix - Nouvelle commande');

    axiosPrivate
      .get(API.getExistingAccounts)
      .then((res) => setAccountsList(res.data));
    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));

    axiosPrivate
      .get(API.getAllWriters, { params: { role: ROLES.WRITER } })
      .then((res) => setWritersList(res.data));
  }, [t]);

  useEffect(() => {
    if (account) {
      axiosPrivate
        .get(API.getAllDevisOfAccount, { params: { account: account } })
        .then((res) => setDevisList(res.data))
        .catch((err) => console.log(err));
      axiosPrivate
        .get(API.getAllWebsiteOfAccount, { params: { account: account } })
        .then((res) => setWebsiteList(res.data));
    }

    setDevis(null);
    setTask(null);
    setTaskId(null);
    setTitled('');
    setSelectedWebsite(null);
    setTextAmount(0);
    setConsultant(null);
    setOrderLanguage(null);
    setOrderThematics([]);
    setDetailedBrief('');
    setClientDocumentation('');
    setSemanticGap('');
    clearAssignedWritersList();
  }, [account]);

  useEffect(() => {
    setMaxNumber(
      textAmount -
        assignedWriters
          .map((x) => x.textAmount)
          .reduce(
            (previousValue, currentValue) =>
              parseInt(previousValue) + parseInt(currentValue)
          )
    );
  }, [assignedWriters, task, textAmount]);

  useEffect(() => {
    if (!taskId) return;
    axiosPrivate
      .get(`/commands/textAmountAllocatedByTaskId/${taskId}`)
      .then((res) => {
        const usedTextAmount = res.data;
        setMaxTextAmount(task.tacheQuantity - usedTextAmount);
        setTextAmount(task.tacheQuantity - usedTextAmount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [taskId]);

  const checkValidForm = useCallback(() => {
    if (
      !account ||
      (!devis && !isDemo) ||
      (!task && !isDemo) ||
      !titled ||
      !selectedWebsite ||
      !textAmount ||
      textAmount > maxTextAmount ||
      !orderLanguage ||
      orderThematics.length === 0 ||
      (!semanticGap && !siloName) ||
      maxNumber < 0 ||
      (!isRedaction && !isTranslation) ||
      (isRedaction &&
        !isTranslation &&
        orderType !== 'PREMIUM' &&
        !contentType) ||
      (isRedaction && !orderType) ||
      (isTranslation && !orderType) ||
      (orderType === 'SMART' && minWords === 0 && maxWords === 0) ||
      (orderType === 'SMART' && minWords > maxWords)
    ) {
      setDisabledForm(true);
      return;
    }

    if (assignedWriters.filter((x) => x.writer).length > 0) {
      let invalid = assignedWriters
        .filter((x) => x.writer)
        .some(
          (x) =>
            (!isGovernance && x.price === 0) ||
            !x.deadline ||
            x.minWords === 0 ||
            x.maxWords === 0 ||
            x.minWords > x.maxWords ||
            parseInt(x.textAmount) === 0 ||
            (x.actualBilling === false && parseInt(x.wordsManagedNumber) === 0)
        );

      setDisabledForm(invalid);
      return;
    }

    setDisabledForm(false);
    return;
  }, [
    account,
    assignedWriters,
    contentType,
    devis,
    maxNumber,
    orderLanguage,
    orderThematics,
    orderType,
    selectedWebsite,
    semanticGap,
    siloName,
    task,
    textAmount,
    titled,
    isRedaction,
    isTranslation,
    minWords,
    maxWords,
  ]);

  useEffect(
    () => checkValidForm(),
    [
      account,
      detailedBrief,
      devis,
      orderLanguage,
      task,
      semanticGap,
      siloName,
      textAmount,
      orderThematics,
      titled,
      selectedWebsite,
      assignedWriters,
      maxNumber,
      orderType,
      contentType,
      checkValidForm,
      isRedaction,
      isTranslation,
      minWords,
      maxWords,
    ]
  );

  function handleCheckboxChange(newStates) {
    if (newStates.checkbox1) {
      setOrderType('PREMIUM');
      setIsContentTypeActive(false);
    } else if (newStates.checkbox2) {
      setOrderType('SMART_WITH_REVIEW');
      setIsContentTypeActive(true);
    } else if (newStates.checkbox3) {
      setOrderType('SMART');
      setIsContentTypeActive(true);
      setShowElement(!showElement);
    }
    setCheckboxStates({ ...checkboxStates, ...newStates });
  }

  const clearAssignedWritersList = () => {
    setAssignedWriters([
      {
        writer: null,
        price: 0,
        deadline: null,
        textAmount: 0,
        minWords: 0,
        maxWords: 0,
        actualBilling: false,
        wordsManagedNumber: 0,
      },
    ]);
  };

  const addWriterOrderLine = () => {
    setAssignedWriters([
      {
        writer: null,
        price: 0,
        deadline: null,
        textAmount: 0,
        minWords: 0,
        maxWords: 0,
        wordsManagedNumber: 0,
        actualBilling: false,
      },
      ...assignedWriters,
    ]);
  };

  const prepareWritersList = (writersList) => {
    if (!writersList) return [];

    return writersList
      .map((option) => ({
        availabilityGroup: option.isAvailable
          ? t('Disponible')
          : t('Indisponible'),
        ...option,
      }))
      .sort((a, b) => {
        if (a.isAvailable === b.isAvailable) {
          return 0;
        }
        return a.isAvailable ? 1 : -1;
      });
  };

  const sortedWriterList = prepareWritersList(writersList);
  const gouvernanceWriter = sortedWriterList.filter((writer) =>
    GOVERNED_WRITERS.includes(writer.mail)
  );

  const updateWriterOrderLine = (index, key, value) => {
    const newList = [...assignedWriters];

    newList[index][key] = value;

    if (key === 'minWords') {
      newList[index].wordsManagedNumber = Math.ceil(
        (newList[index].maxWords + value) / 2
      );
    }

    if (key === 'maxWords') {
      newList[index].wordsManagedNumber = Math.ceil(
        (newList[index].minWords + value) / 2
      );
    }

    setAssignedWriters([...newList]);
  };

  const deleteWriterOrderLine = (index) => {
    let newList = [...assignedWriters];
    newList.splice(index, 1);
    setAssignedWriters([...newList]);
  };

  const callbackFromMultiSelect = (newList) => {
    setOrderThematics(newList);
  };

  const handleCheck = (checkboxName) => {
    if (checkboxName === 'redaction') {
      setIsRedaction(!isRedaction);
    } else if (checkboxName === 'translation') {
      setIsTranslation(!isTranslation);
    } else if ((checkboxName = 'gouvernance')) {
      setIsGovernance(!isGovernance);
    }
  };

  const getTime = (date) => {
    var date = new Date(date);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return date.toISOString();
  };

  const createOrder = async () => {
    const textType =
      isRedaction && isTranslation
        ? 'EDITORIAL_AND_TRANSLATION'
        : isRedaction
        ? 'EDITORIAL'
        : isTranslation
        ? 'TRANSLATION'
        : '';

    const smartOrderBody = {
      account,
      clientDocumentation,
      contentType: contentType?.type || '',
      type: orderType,
      detailedBrief,
      devisName: devis.devisName,
      idDevis: devis.devisId,
      language: orderLanguage,
      editorialContact: editor,
      finalCost: 0,
      minWords,
      maxWords,
      price: 0,
      semanticGap,
      taskName: task.tacheName,
      task: task.tacheId,
      textAmount,
      thematics: orderThematics,
      titled,
      website: selectedWebsite,
      wordsManagedNumber,
      textType: textType,
      nomSilo: siloName,
    };

    const writersCommand = assignedWriters
      .filter(({ writer }) => writer)
      .map(
        ({
          writer,
          price,
          deadline,
          textAmount,
          actualBilling,
          wordsManagedNumber,
          minWords,
          maxWords,
        }) => ({
          id: writer.id,
          price: parseFloat(price),
          deadline: getTime(deadline),
          textAmount: parseInt(textAmount),
          wordsManagedNumber: actualBilling
            ? null
            : parseInt(wordsManagedNumber),
          minWords:
            parseInt(minWords) === 0
              ? parseInt(wordsManagedNumber)
              : parseInt(minWords),
          maxWords:
            parseInt(maxWords) === 0
              ? parseInt(wordsManagedNumber)
              : parseInt(maxWords),
          actualBilling,
        })
      );

    const body = {
      account,
      clientDocumentation,
      contentType: contentType?.type || '',
      type: orderType,
      textType: textType,
      detailedBrief: detailedBrief,
      devisName: devis.devisName,
      idDevis: devis.devisId,
      language: orderLanguage,
      maxPurchaseCost: task.prixAchatMax || 0,
      semanticGap,
      taskName: task.tacheName,
      task: task.tacheId,
      textAmount,
      thematics: orderThematics,
      titled,
      website: selectedWebsite,
      editorialContact: editor,
      writersCommand,
      nomSilo: siloName,
      governed: isGovernance,
    };

    try {
      const url =
        orderType !== 'SMART' ? API.createOrder : API.createSmartOrder;

      const res = await axiosPrivate.post(
        url,
        orderType !== 'SMART' ? body : smartOrderBody
      );
      const totalTextAmount = writersCommand.reduce(
        (acc, { textAmount }) => acc + parseInt(textAmount),
        0
      );

      if (writersCommand.length > 0) {
        dispatch(addOrder({ order: res.data, status: ORDERSTATUS.INPROGRESS }));
      }

      if (writersCommand.length === 0 || textAmount < totalTextAmount) {
        dispatch(
          addOrder({ order: res.data, status: ORDERSTATUS.NONATTRIBUTED })
        );
      }

      setErrorSuccessMsg(
        `${t('La commande pour le client')} ${body.account} ${t(
          'a été créé avec succès'
        )}!`
      );
      setErrorSuccessImg(animationUrl + '/01_CommandeCrée.gif');
      setIsSuccess(true);
      setErrorSuccess(true);
    } catch (e) {
      switch (e.response.status) {
        case 409:
          setErrorSuccessMsg(t('Le titre de la commande existe déjà'));
          break;
        default:
          setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
          break;
      }

      setIsSuccess(false);
      setErrorSuccess(true);
    }
  };
  return (
    <div>
      <div className="content-center">
        <div className="box-header-top">
          <span onClick={history.goBack} className="bt-circle me-3" />
          <h1>{t('Nouvelle commande')}</h1>
        </div>
        <div className="row">
          <div className="box-img-bg bg-2" />
          <div className="box-img-bg bg-1" />
          <div className="col-12 col-md-12 box-screen">
            <div className="box-img-bg bg-3" />
            <div className="box-wrapper">
              <div className="header-title">{t('Titre de la commande')}*</div>
              <div className="row row-mb">
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Compte client')}*</div>

                  <CustomAutocomplete
                    disabled={accountsList.length <= 0}
                    list={accountsList}
                    value={account || ''}
                    setValue={(value) => setAccount(value)}
                    groupBy={(option) => false}
                    getOptionSelected={(option) => option === account}
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option;
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Réf devis')}*</div>

                  <CustomAutocomplete
                    disabled={!devisList || devisList.length <= 0}
                    list={devisList}
                    value={devis}
                    setValue={(option) => setDevis(option)}
                    groupBy={(option) => false}
                    getOptionSelected={(option) =>
                      option.devisName === devis.devisName
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option.devisName;
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Tâche - id tâche')}*</div>

                  <CustomAutocomplete
                    disabled={!devis}
                    list={
                      devisList &&
                      devis &&
                      devisList.filter((x) => x.devisName === devis.devisName)
                        .length > 0
                        ? devisList.find((x) => x.devisName === devis.devisName)
                            .taches
                        : []
                    }
                    value={task}
                    setValue={(option) => {
                      setTask(option);
                      setTaskId(option?.tacheId);
                    }}
                    groupBy={(option) => false}
                    getOptionSelected={(option) =>
                      option.tacheId === task.tacheId
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return (
                        option.tacheName +
                        ' - ' +
                        option.tacheId +
                        ' - ' +
                        option.tacheQuantity
                      );
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">
                    {t('Intitulé de la commande')}*
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control form-ct"
                      value={titled}
                      onChange={(e) => setTitled(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Nom Silo')}</div>
                  <div>
                    <input
                      type="text"
                      value={siloName}
                      placeholder={t('Nom Silo...')}
                      onChange={(e) => setSiloName(e.target.value)}
                      className="form-control form-control-valid form-ct"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('URL du site')}*</div>

                  <CustomAutocomplete
                    disabled={websiteList.length <= 0}
                    list={websiteList}
                    value={selectedWebsite}
                    setValue={(option) => {
                      setSelectedWebsite(option);
                      setConsultant(option ? option.consultant : null);
                      setOrderThematics(option ? option.thematics : []);
                    }}
                    groupBy={(option) => false}
                    getOptionSelected={(option) =>
                      option.id === selectedWebsite.id
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option.urlSite;
                    }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Nombre de textes')}*</div>
                  <div className="number-wrapper">
                    <input
                      min="0"
                      type="number"
                      value={textAmount}
                      onChange={(e) => setTextAmount(e.target.value)}
                      className={
                        textAmount > maxTextAmount
                          ? 'form-control form-control-error form-ct'
                          : 'form-control form-control-valid form-ct'
                      }
                    />
                    <UpDownInputArrows disabled={false} />
                    {textAmount > maxTextAmount ? (
                      <p className="error-note">
                        {t(
                          'Le nombre de mots commandé est supérieur à celui vendu'
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div className="label-title">
                    {t('Gouvernance')}
                    <CustomTooltip
                      title={t(
                        `à cocher si la commande a l'intention d'avoir un rédacteur exclusif pour le client`
                      )}
                      placement="top"
                    >
                      <button
                        type="button"
                        className="btn-circle"
                        style={{ marginLeft: '5px' }}
                      ></button>
                    </CustomTooltip>
                  </div>
                  <div className="box-input-list">
                    <CheckBox
                      name="gouvernance"
                      tick={isGovernance}
                      onCheck={() => handleCheck('gouvernance')}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Consultant')}</div>

                  <div>
                    <input
                      readOnly
                      type="text"
                      value={consultant ? consultant?.name : ''}
                      className="form-control form-ct"
                      style={disabledInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Contact edito')}</div>
                  <div>
                    <CustomAutocomplete
                      disabled={editorsList.length <= 0}
                      list={editorsList}
                      value={editor}
                      setValue={(option) => setEditor(option)}
                      groupBy={(option) => false}
                      getOptionSelected={(option) => option === editor}
                      getOptionDisabled={(option) => false}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="col-12 col-md-7">
                  <div className="label-title">
                    {t('Thématiques d’expertise')}*
                  </div>
                  <CustomChipsAutocomplete
                    callbackFromMultiSelect={callbackFromMultiSelect}
                    value={orderThematics}
                    disabled={!availableThematicsList}
                    list={availableThematicsList}
                    setValue={(option) => setOrderThematics(option)}
                    getOptionLabel={(option) => {
                      return t(option.thematic);
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Langue')}*</div>
                  <CustomAutocomplete
                    disabled={!availableLanguagesList}
                    list={availableLanguagesList}
                    value={orderLanguage}
                    setValue={(option) => setOrderLanguage(option)}
                    groupBy={(option) => false}
                    getOptionSelected={(option) => option === orderLanguage}
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return t(option.language);
                    }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Coût d’achat max/mot')}</div>
                  <div className="input-group input-gr">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      style={disabledInput}
                      value={task ? task.prixAchatMax : ''}
                    />

                    <span className="input-group-text">€</span>
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="col-12 col-md-3 d-flex align-items-center">
                  <div className="label-title me-3"> {t('Rédaction')}*</div>
                  <CheckBox
                    name="redaction"
                    tick={isRedaction}
                    onCheck={() => handleCheck('redaction')}
                  />
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center">
                  <div className="label-title me-3"> {t('Traduction')}*</div>
                  <CheckBox
                    name="translation"
                    tick={isTranslation}
                    onCheck={() => handleCheck('translation')}
                  />
                </div>
              </div>
              <div className="row row-mb  d-flex align-items-center">
                <CheckBoxGroup
                  onCheckboxChange={handleCheckboxChange}
                  isTranslation={isTranslation}
                  isRedaction={isRedaction}
                />
                <div className="col-12 col-md-3">
                  <div className="label-title">
                    {t('Type de contenu')}
                    {orderType !== 'PREMIUM' ? '*' : ''}
                  </div>
                  <div>
                    <CustomAutocomplete
                      disabled={!isContentTypeActive}
                      list={
                        isTranslation && isRedaction === false
                          ? [{ name: t('Traduction'), type: 'TRANSLATION' }]
                          : ORDER_CONTENT_TYPE
                      }
                      value={contentType}
                      setValue={(option) => setContentType(option)}
                      groupBy={(option) => false}
                      getOptionSelected={(option, value) =>
                        option === contentType
                      }
                      getOptionDisabled={(option) => false}
                      getOptionLabel={(option) => {
                        return t(option.name);
                      }}
                    />
                  </div>
                </div>
              </div>

              {assignedWriters.length &&
                !checkboxStates.checkbox3 > 0 &&
                assignedWriters.map((writerOrderLine, index) => (
                  <div className="row row-mb">
                    {
                      <div className="col-12 col-md-2">
                        <div className="label-title">
                          {t('Rédacteur attribué')}
                        </div>
                        <CustomAutocomplete
                          disabled={
                            writersList.length <= 0 || checkboxStates.checkbox3
                          }
                          isWriterList={true}
                          list={
                            isGovernance ? gouvernanceWriter : sortedWriterList
                          }
                          getOptionDisabled={(option) => false}
                          getOptionSelected={(option) =>
                            option.mail === writerOrderLine.writer.mail
                          }
                          value={writerOrderLine.writer}
                          setValue={(option) =>
                            updateWriterOrderLine(index, 'writer', option)
                          }
                          groupBy={(option) => option.availabilityGroup}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                        />
                      </div>
                    }

                    {!isGovernance && (
                      <div className="col-12 col-md-1">
                        <div
                          className={
                            (writerOrderLine.writer &&
                              !writerOrderLine.price) ||
                            (writerOrderLine.writer &&
                              writerOrderLine.price === '0')
                              ? 'label-title-error'
                              : 'label-title'
                          }
                        >
                          {t('Tarif/Mot')}*
                        </div>
                        <div className="number-wrapper input-group input-grl">
                          <span
                            style={{ paddingRight: '5px' }}
                            className={
                              (writerOrderLine.writer &&
                                !writerOrderLine.price) ||
                              (writerOrderLine.writer &&
                                writerOrderLine.price === '0')
                                ? 'input-group-text input-error'
                                : 'input-group-text '
                            }
                          >
                            €
                          </span>
                          <input
                            type="number"
                            onChange={(e) =>
                              updateWriterOrderLine(
                                index,
                                'price',
                                e.target.value
                              )
                            }
                            value={writerOrderLine.price}
                            min="0"
                            max="0.99"
                            className={
                              (writerOrderLine.writer &&
                                !writerOrderLine.price) ||
                              (writerOrderLine.writer &&
                                writerOrderLine.price === '0') ||
                              parseFloat(writerOrderLine.price) >= 1
                                ? 'form-control form-control-error form-ct'
                                : 'form-control form-control-valid form-ct'
                            }
                          />
                          <UpDownInputArrows disabled={false} />
                        </div>
                        {(writerOrderLine.writer && !writerOrderLine.price) ||
                        (writerOrderLine.writer &&
                          writerOrderLine.price === '0') ||
                        parseFloat(writerOrderLine.price) >= 1 ? (
                          <p className="error-note">
                            {t('Ce champs doit être sup à 0 et inférieur à 1')}
                          </p>
                        ) : null}
                      </div>
                    )}

                    <div className="col-12 col-md-2">
                      <div
                        className={
                          writerOrderLine.writer && !writerOrderLine.deadline
                            ? 'label-title-error'
                            : 'label-title'
                        }
                      >
                        {t('Deadline')}*
                      </div>
                      <div className="wrapper-date">
                        <DatePicker
                          disabled={checkboxStates.checkbox3}
                          className={
                            writerOrderLine.writer && !writerOrderLine.deadline
                              ? 'form-control form-control-error form-ct'
                              : 'form-control form-control-valid form-ct'
                          }
                          selected={
                            writerOrderLine.deadline
                              ? writerOrderLine.deadline
                              : ''
                          }
                          onChange={(date) => {
                            updateWriterOrderLine(index, 'deadline', date);
                          }}
                        />
                        <div
                          id="date-button"
                          style={{ backgroundColor: 'white' }}
                          className="box-icon-calendar"
                        >
                          <img
                            src="/Images/icon-calendar.png"
                            alt="calendar-icon"
                          />
                        </div>
                      </div>
                      {writerOrderLine.writer && !writerOrderLine.deadline ? (
                        <p className="error-note">
                          {' '}
                          {t('Ce champs est obligatoire')}{' '}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-1">
                      <div
                        className={
                          (writerOrderLine.writer &&
                            !writerOrderLine.textAmount) ||
                          (writerOrderLine.writer && maxNumber < 0)
                            ? 'label-title-error'
                            : 'label-title'
                        }
                      >
                        {t('Nb textes')}*
                      </div>
                      <div className="number-wrapper">
                        <input
                          type="number"
                          className={
                            (writerOrderLine.writer &&
                              !writerOrderLine.textAmount) ||
                            (writerOrderLine.writer && maxNumber < 0)
                              ? 'form-control form-control-error form-ct'
                              : 'form-control form-control-valid form-ct'
                          }
                          min="0"
                          value={writerOrderLine.textAmount}
                          onChange={(e) =>
                            updateWriterOrderLine(
                              index,
                              'textAmount',
                              e.target.value
                            )
                          }
                        />
                        <UpDownInputArrows disabled={false} />
                      </div>
                      {(writerOrderLine.writer &&
                        !writerOrderLine.textAmount) ||
                      (writerOrderLine.writer &&
                        writerOrderLine.textAmount === '0') ? (
                        <p className="error-note">
                          {t('Ce champs doit être sup à 0')}
                        </p>
                      ) : null}
                      {writerOrderLine.writer && maxNumber < 0 ? (
                        <p className="error-note">
                          {`${t('Vous avez')} ${Math.abs(maxNumber)} ${t(
                            'textes en plus'
                          )}`}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-1">
                      <div
                        className={
                          (writerOrderLine.writer &&
                            writerOrderLine.minWords >
                              writerOrderLine.maxWords) ||
                          (writerOrderLine.writer &&
                            writerOrderLine.minWords === 0)
                            ? 'label-title-error'
                            : 'label-title'
                        }
                      >
                        {t('Min')}*
                      </div>
                      <div className="number-wrapper">
                        <input
                          type="number"
                          className={
                            (writerOrderLine.writer &&
                              writerOrderLine.minWords >
                                writerOrderLine.maxWords) ||
                            (writerOrderLine.writer &&
                              writerOrderLine.minWords === 0)
                              ? 'form-control form-control-error form-ct'
                              : 'form-control form-control-valid form-ct'
                          }
                          min="0"
                          value={writerOrderLine.minWords}
                          onChange={(e) => {
                            updateWriterOrderLine(
                              index,
                              'minWords',
                              parseInt(e.target.value)
                            );
                          }}
                        />
                        <UpDownInputArrows disabled={false} />
                      </div>
                      {writerOrderLine.writer &&
                      writerOrderLine.minWords > writerOrderLine.maxWords ? (
                        <p className="error-note">
                          {t('Max doit être sup à Min')}
                        </p>
                      ) : writerOrderLine.writer &&
                        writerOrderLine.minWords === 0 ? (
                        <p className="error-note">
                          {t('Ce champs est obligatoire')}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-12 col-md-1">
                      <div
                        className={
                          (writerOrderLine.writer &&
                            writerOrderLine.minWords >
                              writerOrderLine.maxWords) ||
                          (writerOrderLine.writer &&
                            writerOrderLine.maxWords === 0)
                            ? 'label-title-error'
                            : 'label-title'
                        }
                      >
                        {t('Max')}*
                      </div>
                      <div className="number-wrapper">
                        <input
                          type="number"
                          className={
                            (writerOrderLine.writer &&
                              writerOrderLine.minWords >
                                writerOrderLine.maxWords) ||
                            (writerOrderLine.writer &&
                              writerOrderLine.maxWords === 0)
                              ? 'form-control form-control-error form-ct'
                              : 'form-control form-control-valid form-ct'
                          }
                          min={writerOrderLine.minWords}
                          value={writerOrderLine.maxWords}
                          onChange={(e) => {
                            updateWriterOrderLine(
                              index,
                              'maxWords',
                              parseInt(e.target.value)
                            );
                          }}
                        />
                        <UpDownInputArrows disabled={false} />
                      </div>
                      {writerOrderLine.writer &&
                      writerOrderLine.minWords > writerOrderLine.maxWords ? (
                        <p className="error-note">
                          {t('Max doit être sup à Min')}
                        </p>
                      ) : writerOrderLine.writer &&
                        writerOrderLine.maxWords === 0 ? (
                        <p className="error-note">
                          {t('Ce champs est obligatoire')}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-12 col-md-2">
                      <div
                        className={
                          (writerOrderLine.writer &&
                            writerOrderLine.actualBilling === false &&
                            !writerOrderLine.wordsManagedNumber) ||
                          (writerOrderLine.writer &&
                            writerOrderLine.actualBilling === false &&
                            writerOrderLine.wordsManagedNumber === '0')
                            ? 'label-title-error'
                            : 'label-title'
                        }
                      >
                        {t('Nombre de mots par texte')}
                      </div>
                      <div className="number-wrapper">
                        <input
                          type="number"
                          min="0"
                          className={
                            (writerOrderLine.writer &&
                              writerOrderLine.actualBilling === false &&
                              !writerOrderLine.wordsManagedNumber) ||
                            (writerOrderLine.writer &&
                              writerOrderLine.actualBilling === false &&
                              writerOrderLine.wordsManagedNumber === '0')
                              ? 'form-control form-control-error form-ct'
                              : 'form-control form-control-valid form-ct'
                          }
                          style={
                            writerOrderLine.actualBilling ? disabledInput : {}
                          }
                          value={writerOrderLine.wordsManagedNumber}
                          readOnly={writerOrderLine.actualBilling}
                          onChange={(e) =>
                            updateWriterOrderLine(
                              index,
                              'wordsManagedNumber',
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <UpDownInputArrows
                          disabled={writerOrderLine.actualBilling}
                        />
                      </div>
                      {(writerOrderLine.writer &&
                        writerOrderLine.actualBilling === false &&
                        !writerOrderLine.wordsManagedNumber) ||
                      (writerOrderLine.writer &&
                        writerOrderLine.actualBilling === false &&
                        writerOrderLine.wordsManagedNumber === '0') ? (
                        <p className="error-note">
                          {t(
                            "Ce champs doit être sup à 0 car facturation n'est pas au réel"
                          )}
                        </p>
                      ) : null}
                    </div>

                    {!isGovernance && (
                      <div className="col-12 col-md-2">
                        <div className="label-title">
                          {t('Facturation au réel')}
                          <CustomTooltip
                            title={t(
                              'à cocher si le nombre de mots est différent par texte'
                            )}
                            placement="top"
                          >
                            <button
                              type="button"
                              className="btn-circle"
                              style={{ marginLeft: '5px' }}
                            ></button>
                          </CustomTooltip>
                        </div>
                        <div className="box-input-list">
                          <div
                            className="box-checkbox"
                            onClick={(event) =>
                              updateWriterOrderLine(
                                index,
                                'actualBilling',
                                event.target.checked
                              )
                            }
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={writerOrderLine.actualBilling}
                            />
                          </div>
                          {false ? (
                            <div onClick={() => deleteWriterOrderLine(index)}>
                              <span className="bt-minus-circle"></span>
                            </div>
                          ) : true ? (
                            <div onClick={addWriterOrderLine}>
                              <span className="bt-plus-circle"></span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              {checkboxStates.checkbox3 ? (
                <div className="row row-mb">
                  {/* <div className="col-12 col-md-1">
                    <div className="label-title">{t('Tarif/Mot')}*</div>
                    <div className="number-wrapper input-group input-grl">
                      <span
                        style={{ paddingRight: '5px' }}
                        className="input-group-text"
                      >
                        €
                      </span>
                      <input
                        disabled={true}
                        value="0.07"
                        type="number"
                        min="0"
                        max="0.99"
                        className="form-control form-control-valid form-ct"
                      />
                    </div>
                  </div> */}

                  <div className="col-12 col-md-1">
                    <div
                      className={
                        minWords > maxWords || minWords === 0
                          ? 'label-title-error'
                          : 'label-title'
                      }
                    >
                      {t('Min')}*
                    </div>
                    <div className="number-wrapper">
                      <input
                        className={
                          minWords === 0 || minWords > maxWords
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                        type="number"
                        min="0"
                        value={minWords}
                        onChange={(e) => {
                          setMinWords(parseInt(e.target.value));
                          setWordsManagedNumber(
                            Math.ceil((maxWords + parseInt(e.target.value)) / 2)
                          );
                        }}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {minWords > maxWords ? (
                      <p className="error-note">
                        {t('Max doit être sup à Min')}
                      </p>
                    ) : minWords === 0 ? (
                      <p className="error-note">
                        {t('Ce champs est obligatoire')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-12 col-md-1">
                    <div
                      className={
                        minWords > maxWords || maxWords === 0
                          ? 'label-title-error'
                          : 'label-title'
                      }
                    >
                      {t('Max')}*
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        className={
                          maxWords === 0 || maxWords < minWords
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                        value={maxWords}
                        onChange={(e) => {
                          setMaxWords(parseInt(e.target.value));
                          setWordsManagedNumber(
                            Math.ceil((minWords + parseInt(e.target.value)) / 2)
                          );
                        }}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {minWords > maxWords ? (
                      <p className="error-note">
                        {t('Max doit être sup à Min')}
                      </p>
                    ) : maxWords === 0 ? (
                      <p className="error-note">
                        {t('Ce champs est obligatoire')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Nombre de mots par texte')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-valid form-ct"
                        value={wordsManagedNumber}
                        onChange={(e) => {
                          setWordsManagedNumber(parseInt(e.target.value));
                        }}
                        style={actualBilling ? disabledInput : {}}
                        readOnly={actualBilling}
                      />
                      <UpDownInputArrows />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">
                      {t('Facturation au réel')}
                      <CustomTooltip
                        title={t(
                          'à cocher si le nombre de mots est différent par texte'
                        )}
                        placement="top"
                      >
                        <button
                          type="button"
                          className="btn-circle"
                          style={{ marginLeft: '5px' }}
                        ></button>
                      </CustomTooltip>
                    </div>
                    <div className="box-input-list">
                      <div
                        className="box-checkbox"
                        onClick={(event) =>
                          setActualBilling(event.target.checked)
                        }
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={actualBilling}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="row row-mb">
                <div className="col-12 col-md-4">
                  <div className="label-title">{t('Brief détaillé')}</div>
                  <div>
                    <input
                      type="text"
                      value={detailedBrief}
                      placeholder={t('Lien drive...')}
                      onChange={(e) => setDetailedBrief(e.target.value)}
                      className="form-control form-control-valid form-ct"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div
                    className={
                      semanticGapFocus && !semanticGap && !siloName
                        ? 'label-title-error'
                        : 'label-title'
                    }
                  >
                    {t('Gap sémantique')}
                    {siloName !== '' ? '' : '*'}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder={t('Lien drive...')}
                      value={semanticGap}
                      onChange={(e) => setSemanticGap(e.target.value)}
                      onFocus={() => setSemanticGapFocus(true)}
                      onBlur={() => setSemanticGapFocus(false)}
                      className={
                        semanticGapFocus && !semanticGap && !siloName
                          ? 'form-control form-control-error form-ct'
                          : 'form-control form-control-valid form-ct'
                      }
                    />
                  </div>
                  {semanticGapFocus && !semanticGap && !siloName ? (
                    <p className="error-note">
                      {t('Ce champs est obligatoire')}{' '}
                    </p>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <div className="label-title">{t('Documentation')}</div>
                  <div>
                    <input
                      type="text"
                      placeholder={t('Lien drive...')}
                      value={clientDocumentation}
                      onChange={(e) => setClientDocumentation(e.target.value)}
                      className="form-control form-control-valid form-ct"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-button-bt">
              <div className="me-4">*{t('Champs obligatoires')}</div>
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y">
                    {t('Sauvegarder')}
                  </button>
                ) : (
                  <button className="bt-submit-y" onClick={createOrder}>
                    {t('Sauvegarder')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isSuccess ? history.push('/') : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
        imageUrl={errorSuccessImg}
      />
    </div>
  );
}
