import React from 'react';
import CheckBox from 'components/shared/CheckBox';
import {
  createHandleCheckBoxChange,
  handleDeployCheckBoxChange,
} from 'helpers/CheckBoxHelper';

const TextCheckBox = ({
  orderType,
  text,
  selectionHandlers: {
    selectedTextsForRevisionOrValidation = [],
    setSelectedTextsForRevisionOrValidation = () => {},
    selectedTextsForValidation = [],
    setSelectedTextsForValidation = () => {},
    deploymentQueue = [],
    setDeploymentQueue = () => {},
  } = {},
  eligibility: {
    isEligibleForRevision = false,
    isAwaitingDeployment = false,
    isEligibleForValidation = false,
    isTextEligibleForValidationAndRevision = false,
  } = {},
}) => {
  const { id: textId, keyword, url } = text || {};

  const handleCheckBoxChangeForRevisionOrValidation =
    createHandleCheckBoxChange(
      selectedTextsForRevisionOrValidation,
      setSelectedTextsForRevisionOrValidation
    );

  const handleCheckBoxChangeForValidation = createHandleCheckBoxChange(
    selectedTextsForValidation,
    setSelectedTextsForValidation
  );

  const getTick = () => {
    if (isTextEligibleForValidationAndRevision) {
      return selectedTextsForRevisionOrValidation.includes(textId);
    }
    if (isAwaitingDeployment) {
      return deploymentQueue?.some((item) => item.id === textId);
    }
    if (isEligibleForValidation) {
      return selectedTextsForValidation.includes(textId);
    }
    return false;
  };

  const getOnCheck = () => {
    if (isTextEligibleForValidationAndRevision) {
      return () => handleCheckBoxChangeForRevisionOrValidation(textId);
    }
    if (isAwaitingDeployment) {
      return () =>
        handleDeployCheckBoxChange(textId, keyword, url, setDeploymentQueue);
    }
    if (isEligibleForValidation) {
      return () => handleCheckBoxChangeForValidation(textId);
    }
    return null;
  };

  const shouldRenderCheckBox =
    isTextEligibleForValidationAndRevision ||
    isAwaitingDeployment ||
    (orderType === 'SMART' && isEligibleForValidation);

  return (
    shouldRenderCheckBox && (
      <CheckBox
        key={textId}
        name={`text-${textId}`}
        tick={getTick()}
        onCheck={getOnCheck()}
      />
    )
  );
};

export default TextCheckBox;
