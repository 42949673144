import axiosPrivate from 'config/axiosPrivate';

export const autoSaveText = async (
  newActualTextContent,
  newActualHtmlContent,
  textForm,
  initialHtmlContent,
  initialTextContent,
  orderType
) => {
  try {
    const textData = {
      actualHtmlContent: newActualHtmlContent,
      actualTextContent:
        orderType !== 'PREMIUM' ? newActualHtmlContent : newActualTextContent,
      initialHtmlContent: initialHtmlContent,
      initialTextContent: initialTextContent,
    };
    const response = await axiosPrivate.put(
      `/texts/auto-save/${textForm.id}`,
      textData
    );

    return response.data;
  } catch (error) {
    console.error('Failed to save data:', error);
    throw error; // Throw error to be caught in the autosave hook
  }
};
