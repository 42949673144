import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { adminOrderDisplaySelector } from 'store/Admin/OrderDisplay';
import {
  fetchAdminOrderLineDisplay,
  fetchAdminTextOrderDisplay,
} from 'store/Admin/OrderDisplay/features';
import { removeOrder } from 'store/Admin/OrderList';

import OrderDetails from 'components/Admin/OrderDetails';
import { OrderTextsDetailsList } from 'components/Admin/OrderTextsDetailsList';

import { OrderLineDetails } from 'components/Admin/OrderLineDetails';
import OrderHistory from 'components/shared/OrderHistory';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { useDispatch } from 'react-redux';

import './OrderDisplay.css';

import axiosPrivate from 'config/axiosPrivate';
import { ORDERSTATUS } from 'utils/Constants';
import { verifyAllTextsDeletionCriteria } from 'helpers/OrderTextsHelper';
import OrderTextDeleteButton from 'components/shared/OrderDetails/OrderTextDeleteButton';
import { useInterval } from 'utils/hooks/useInterval';

export default function OrderDisplay() {
  const {
    order,
    status,
    textList,
    isTextLoading,
    isTextError,
    isLoading,
    isError,
  } = useSelector(adminOrderDisplaySelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navItems = [
    { text: t('Détail de la commande'), active: 1 },
    { text: t('Détail ligne de commande'), active: 2 },
    { text: t('Historique'), active: 3 },
  ];
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [areAllTextsDeletable, setAreTextsDeletable] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [regenerateTextCommandeLoader, setRegenerateTextCommandeLoader] =
    useState(false);
  const [active, setActive] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isReload, setIsReload] = useState(false);
  const [jobStatus, setJobStatus] = useState('COMPLETED');
  const [isReloadingCompleted, setIsReloadingCompleted] = useState(false);

  const fiveMinutesInMs = 5 * 60 * 1000; // 5 minutes in millisecon

  useEffect(() => {
    document.title = t('Typix - Détails de la commande');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  const fetchAndUpdate = () => {
    if (!isLoading && !isError && !isDeleted && !isTextError) {
      setTitle(order.title);
      axiosPrivate
        .get(`/commands/${order.id}`)
        .then((res) => {
          if (res.data?.jobStatus) {
            const { jobStatus } = res.data;
            if (jobStatus === 'PROCESSING') {
              setRegenerateTextCommandeLoader(true);
            } else {
              dispatch(fetchAdminOrderLineDisplay(order.id));
              dispatch(fetchAdminTextOrderDisplay(order.id));
              setRegenerateTextCommandeLoader(false);
              setIsReloadingCompleted(true);
            }
          }
        })

        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    fetchAndUpdate();

    const intervalId = setInterval(() => {
      fetchAndUpdate();
    }, fiveMinutesInMs);

    return () => clearInterval(intervalId);
  }, [isLoading, isError, isDeleted, order]);

  // Fetch and update function
  // const fetchAndUpdate = () => {
  //   if (!isLoading && !isError && !isDeleted && !isTextError) {
  //     setTitle(order.title);
  //     axiosPrivate
  //       .get(`/commands/${order.id}`)
  //       .then((res) => {
  //         const newJobStatus = res.data?.jobStatus;

  //         // Update `jobStatus` here without additional logic.
  //         // Any response to this update will happen in `useEffect`.

  //         setJobStatus(newJobStatus);
  //       })
  //       .catch((err) => console.error(err));
  //   }
  // };

  // useEffect(() => {
  //   fetchAndUpdate();
  // }, [order, isLoading, isError, isDeleted, isTextError, isTextLoading]);

  // useInterval(() => {
  //   fetchAndUpdate();
  // }, 600000);

  // // Effect to react to jobStatus updates
  // useEffect(() => {
  //   if (!order) return;
  //   if (jobStatus === 'PROCESSING') {
  //     setRegenerateTextCommandeLoader(true);
  //   } else if (jobStatus === 'COMPLETED') {
  //     dispatch(fetchAdminOrderLineDisplay(order.id));
  //     dispatch(fetchAdminTextOrderDisplay(order.id));
  //     setRegenerateTextCommandeLoader(false);
  //   }
  // }, [jobStatus]); // This effect will run every time `jobStatus` changes

  useEffect(() => {
    const allDeletable = verifyAllTextsDeletionCriteria(textList);
    setAreTextsDeletable(allDeletable);
  }, [textList]);

  const reloadTexts = () => {
    axiosPrivate
      .put(`/commands/restartChatGBTJob/${order.id}`)
      .then((res) => {
        setRegenerateTextCommandeLoader(true);
        setConfirmation(false);
      })

      .catch((err) => {
        console.log(err);
        setConfirmation(false);
      });
  };

  const deleteOrder = () => {
    axiosPrivate
      .delete(`/commands/${order.id}`)
      .then(() => {
        setIsDeleted(true);
        setConfirmation(false);
        dispatch(removeOrder({ id: order.id, status: status }));
        setErrorSuccessMsg(`${t('La commande a été supprimé avec succès')} !`);
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsDeleted(false);
        setIsSuccess(false);
        setConfirmation(false);
        if (e.response.status) {
          setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        }
        setErrorSuccess(true);
      });
  };

  if (isLoading) return <Loader />;

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1 className="me-3">{order.title}</h1>
        {status !== ORDERSTATUS.VALID && textList.length === 0 ? (
          <div
            className="bt-icon-trash"
            onClick={() => {
              setConfirmationMsg(
                `${t('Voulez-vous vraiment supprimer la commande de')} ${
                  order.title
                } ? `
              );
              setConfirmation(true);
            }}
          >
            <img src="Images/icon-trash.png" alt="icon-trash" />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="row">
        <div className="col-12 col-md-8 mb-2">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => setActive(item.active)}
                  className={`nav-link ${
                    item.active === active ? 'active' : ''
                  }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
          {active === 1 && (
            <OrderDetails
              searchValue={searchValue}
              textsPerPage={elementsPerPage}
            />
          )}
        </div>
        {active === 1 ? (
          <div className="col-12 col-md-4">
            <div className="box-text-title">
              <div className="box-ch">
                <span>{t('Contenu disponible')}</span>
                <CustomTooltip
                  title={
                    <span>
                      <p>
                        {t('Noir')} :{' '}
                        {t('contenu en attente de vérification client')}
                      </p>
                      <p>
                        {t('Orange')} :{' '}
                        {t('contenu en attente de repasse rédacteur')}
                      </p>
                      <p>
                        {t('Violet')} :{' '}
                        {t('contenu repassé, en attente validation client')}
                      </p>
                      <p>
                        {t('Vert')} : {t('contenu validé par le client')}
                      </p>
                    </span>
                  }
                  placement="top"
                >
                  <button type="button" className="btn-circle"></button>
                </CustomTooltip>
                {order.type !== 'PREMIUM' && (
                  <div
                    onClick={() => {
                      setConfirmationMsg(
                        t(
                          'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?'
                        )
                      );
                      setConfirmation(true);
                      setIsReload(true);
                    }}
                    className={
                      regenerateTextCommandeLoader
                        ? 'bt-reload-dynamic-all-text'
                        : 'bt-reload-static-all-text'
                    }
                  ></div>
                )}
              </div>
              {order?.type !== 'PREMIUM' &&
                textList.length > 0 &&
                areAllTextsDeletable && (
                  <OrderTextDeleteButton
                    orderTitle={order.title}
                    orderId={order.id}
                  />
                )}
              {textList.length > 0 && (
                <ExcelExportButton
                  orderTitle={order.title}
                  orderId={order.id}
                  orderLanguage={order.language}
                />
              )}
            </div>
            <OrderTextsDetailsList
              orderId={order.id}
              orderTextAmount={order.textAmount}
              regenerateTextLoader={regenerateTextCommandeLoader}
              isReloadingCompleted={isReloadingCompleted}
              setIsReloadingCompleted={setIsReloadingCompleted}
            />
          </div>
        ) : (
          ''
        )}
      </div>

      {active === 2 && <OrderLineDetails searchValue={searchValue} />}
      {active === 3 && (
        <OrderHistory
          searchValue={searchValue}
          elementsPerPage={elementsPerPage}
        />
      )}
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isDeleted ? history.push('/') : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={isReload ? reloadTexts : deleteOrder}
        isReload={isReload}
      />
    </div>
  );
}
