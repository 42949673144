export const createHandleCheckBoxChange =
  (selectedTexts, setSelectedTexts) => (textId) => {
    if (selectedTexts.includes(textId)) {
      setSelectedTexts((prevSelectedTexts) =>
        prevSelectedTexts.filter((id) => id !== textId)
      );
    } else {
      setSelectedTexts((prevSelectedTexts) => [...prevSelectedTexts, textId]);
    }
  };

export const handleDeployCheckBoxChange = (
  textId,
  textTitle,
  textUrl,
  setDeploymentQueue
) => {
  setDeploymentQueue((prevSelectedTexts) =>
    prevSelectedTexts.some((item) => item.id === textId)
      ? prevSelectedTexts.filter((item) => item.id !== textId)
      : [
          ...prevSelectedTexts,
          { id: textId, title: textTitle, url: textUrl, deploy: true },
        ]
  );
};

export const shouldShowRevisionCheckBox = (
  orderType,
  userRole,
  availbleRevisionNumber,
  orderRevisionDisabled
) =>
  orderType !== 'SMART' &&
  userRole === 'CLIENT' &&
  availbleRevisionNumber > 1 &&
  !orderRevisionDisabled;

export const shouldShowValidationCheckBox = (
  userRole,
  availableValidationNumber,
  orderType
) =>
  userRole === 'CLIENT' &&
  availableValidationNumber > 0 &&
  orderType === 'SMART';

export const shouldDisplayValidationAndRevisionCheckBox = (
  userRole,
  availableValidationNumber,
  availbleRevisionNumber,
  orderType
) =>
  userRole === 'CLIENT' &&
  (availableValidationNumber > 0 || availbleRevisionNumber > 0) &&
  orderType !== 'SMART';

export const handleRevisionCheck = (
  e,
  sortedText,
  setSelectedTextsForRevision
) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    const selectedIds = sortedText
      .filter(
        (text) =>
          !text.revisionSent &&
          !text.globalRevisionSent &&
          !text.validated &&
          !text.revisionDone
      )
      .map((text) => text.id);
    setSelectedTextsForRevision(selectedIds);
  } else {
    setSelectedTextsForRevision([]);
  }
};

export const handleValidationCheck = (
  e,
  sortedText,
  setSelectedTextsForValidation,
  isTextEligibleForValidation
) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    const selectedIds = sortedText
      .filter(isTextEligibleForValidation)
      .map((text) => text.id);
    setSelectedTextsForValidation(selectedIds);
  } else {
    setSelectedTextsForValidation([]);
  }
};

export const handleRedraftingCheck = (
  e,
  sortedText,
  setSelectedTextsToReDraft,
  isTextEligibleToReputInDraft
) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    const selectedIds = sortedText
      .filter(isTextEligibleToReputInDraft)
      .map((text) => text.id);
    setSelectedTextsToReDraft(selectedIds);
  } else {
    setSelectedTextsToReDraft([]);
  }
};
